import { BookingStatus, ServiceProductType } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';

export const bookingStatusLabels = {
  [BookingStatus.TRANSACTIONAL]: 'Transactional',
  [BookingStatus.PENDING_CUSTOMER_SIGNATURE]: 'Pending Customer Signature',
  [BookingStatus.PENDING_ORDER_NUMBER]: 'Pending Order Number',
  [BookingStatus.WORKING_ENGAGEMENT]: 'Working Engagement',
  [BookingStatus.PROJECT_BOOKED]: 'Project Booked',
  [BookingStatus.BOOKED]: 'Booked',
  [BookingStatus.CANCELED_ENGAGEMENT]: 'Canceled Engagement',
};

export const serviceProductTypeLabels = {
  [ServiceProductType.COMPLEX]: 'Complex',
  [ServiceProductType.TRANSACTIONAL]: 'Transactional',
};
