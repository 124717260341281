import qs from 'qs';
import { netsparkAxios } from 'core/services/HTTPService';
import {
  // eslint-disable-next-line no-unused-vars
  ICreateOrderItem, IProduct, ISalesTSDItem, IProductParams, ICreateProduct,
  // eslint-disable-next-line no-unused-vars
  IProductCategory, IProductCategoriesParams, ICarriersParams, ICarrier,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import {
  productsPath, salesSowMataPath, salesSowPath, sowDataUrl,
  quoteRequestUrl, getQuoteProvideUrl, contractBundlesUrl,
  getContractBundlesByIdUrl,
  getSalesSowOrderFormUrl, salesOrderActionsUrl,
  getSalesOrderActionsProvideUrl,
  getContractProvideUrl, activeNumbersUrl, getEngagementAcceptedUrl, renewalSowUrl,
  getEscalationSupportUrl, getEscalationSupportDataByIdUrl, getRenewalSowItemUrl,
  getRenewalSowEngagementAcceptedUrl, getBookingInfoBookedOrderUrl,
  getOrderCancelUrlById, retentionBookingUrl,
  exportSowDataUrl, getTSDListUrl, getProductCategoriesListUrl,
  getSowDataEngagementAcceptedUrl, getCarriersListUrl,
  getPmEngagementAcceptedUrl, getBillingEngagementAcceptedUrl, salesActiveNumbersMetaUrl,
} from 'core/api/sales.api/constants';
import { IRequestPaging } from 'core/services/HTTPService.models';
import {
  // eslint-disable-next-line no-unused-vars
  IEscalationSupportParams, IPutEscalationSupportParams, IDraftQuote, ISalesSow,
  // eslint-disable-next-line no-unused-vars
  ISalesSowMetaItem, IQuote, IContractBundlesById, IOrderCancel,
} from '@EngagementsSowData/salesSow.model';
import {
  IActiveNumbersItem,
  ISalesActiveNumbersMetaItem,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsActiveNumbers/activeNumbers.model';
import { IRenewalSowDataItem } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsRenewalSowData/renewalSowData.models';
import { IEscalationSupportItem } from '@dashboardEscalationSupport/EscalationSupportTable/model';
import { IRetentionBookingItem } from '@dashboardWirelineRetentionDepartment/RetentionManagerDepartment.models';
// eslint-disable-next-line no-unused-vars
import { IBookedOrderParams } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/bookedOrder.model';
// eslint-disable-next-line no-unused-vars
import { ExportParams } from 'shared/ExportTableXlsxFile/ExportTableXlsxFile.models';

export const getProducts = (params?: IProductParams) => netsparkAxios.get<IProduct[]>(
  productsPath, {
    params,
    paramsSerializer: (paramsData) => qs.stringify(paramsData, { arrayFormat: 'comma' }),
  },
);

export const createProduct = (requestData: ICreateProduct) => (
  netsparkAxios.post<IProduct>(productsPath, requestData)
);

export const createOrder = async (orders: ICreateOrderItem[]) => (
  netsparkAxios.post(sowDataUrl, orders)
);

export const getSalesSow = (signal, params) => (
  netsparkAxios.get<IRequestPaging<ISalesSow>>(salesSowPath, { signal, params })
);

export const getSalesSowById = (id: string | number) => (
  netsparkAxios.get(`${salesSowPath}${id}/`)
);

export const patchSalesSowById = (id: string | number, params: object) => (
  netsparkAxios.patch(`${salesSowPath}${id}/`, params)
);

export const getSalesSowMeta = () => netsparkAxios.get<ISalesSowMetaItem[]>(salesSowMataPath);

export const getSalesActiveNumbersMeta = () => netsparkAxios.get<ISalesActiveNumbersMetaItem[]>(
  salesActiveNumbersMetaUrl,
);

export const quoteRequest = (
  draftQuote: IDraftQuote,
) => netsparkAxios.post<IQuote>(quoteRequestUrl, draftQuote);

export const quoteProvide = (
  quoteId: number | string,
) => netsparkAxios.post<IQuote>(getQuoteProvideUrl(quoteId));

export const contractBundles = (params) => netsparkAxios.post(contractBundlesUrl, params);

export const contractProvide = (
  contractId: number | string,
) => netsparkAxios.post(getContractProvideUrl(contractId));

export const getContractBundles = (
  bundleId: number | string,
) => netsparkAxios.get<IContractBundlesById>(getContractBundlesByIdUrl(bundleId));

export const getSalesSowOrderForm = (id: string | number) => (
  netsparkAxios.get(getSalesSowOrderFormUrl(id))
);

export const putSalesSowOrderForm = (id: string | number, params: object) => (
  netsparkAxios.put(getSalesSowOrderFormUrl(id), params)
);

export const postSalesOrderActions = (params: { orderId: number }[]) => (
  netsparkAxios.post(salesOrderActionsUrl, params)
);

export const getActiveNumbers = (signal: AbortSignal, params: object) => (
  netsparkAxios.get<IRequestPaging<IActiveNumbersItem>>(activeNumbersUrl, { signal, params })
);

export const postSalesOrderActionsProvide = async (id: string | number) => (
  netsparkAxios.post(getSalesOrderActionsProvideUrl(id),
  )
);

export const getSalesActiveNumbersById = (id: string | number) => (
  netsparkAxios.get(`${activeNumbersUrl}${id}/`)
);

export const patchSalesActiveNumbersById = (id: string | number, params: object) => (
  netsparkAxios.patch(`${activeNumbersUrl}${id}/`, params)
);

export const postEngagementAccepted = (orderId: string | number) => (
  netsparkAxios.post(getEngagementAcceptedUrl(orderId))
);

export const postPmEngagementAccepted = (orderId: string | number) => (
  netsparkAxios.post(getPmEngagementAcceptedUrl(orderId))
);

export const postBillingEngagementAccepted = (orderId: string | number) => (
  netsparkAxios.post(getBillingEngagementAcceptedUrl(orderId))
);

export const postSowDataEngagementAccepted = (orderId: string | number) => (
  netsparkAxios.post(getSowDataEngagementAcceptedUrl(orderId))
);

export const getRenewalSow = (signal: AbortSignal, params: object) => (
  netsparkAxios.get<IRequestPaging<IRenewalSowDataItem>>(renewalSowUrl, { signal, params })
);

export const getRenewalSowItemById = (id: string | number) => (
  netsparkAxios.get<IRenewalSowDataItem>(getRenewalSowItemUrl(id))
);

export const postRenewalSowEngagementAccepted = (id: string | number) => (
  netsparkAxios.post(getRenewalSowEngagementAcceptedUrl(id))
);

export const postBookingInfoBookedOrder = (params: IBookedOrderParams) => (
  netsparkAxios.post(getBookingInfoBookedOrderUrl(), params)
);

export const patchRenewalSowItemById = (
  id: string | number,
  renewalSowItem: IRenewalSowDataItem,
) => (
  netsparkAxios.patch<IRenewalSowDataItem>(getRenewalSowItemUrl(id), renewalSowItem)
);

export const postEscalationSupport = (id: string | number, params: IEscalationSupportParams) => (
  netsparkAxios.post(getEscalationSupportUrl(id), params)
);

export const putEscalationSupport = (id: string | number, params: IPutEscalationSupportParams) => (
  netsparkAxios.put(getEscalationSupportDataByIdUrl(id), params)
);

export const getEscalationSupportItem = (id: string | number) => (
  netsparkAxios.get<IEscalationSupportItem>(getEscalationSupportDataByIdUrl(id))
);

export const postOrderCancelById = (id: string | number, params: IOrderCancel) => (
  netsparkAxios.post(getOrderCancelUrlById(id), params)
);

export const getRetentionBookingList = (
  signal: AbortSignal,
  params: {
    page: number;
    page_size: number;
    ordering?: string | undefined;
  }) => (
  netsparkAxios.get<IRequestPaging<IRetentionBookingItem>>(retentionBookingUrl, { signal, params })
);

export const exportSowData = (exportParams: ExportParams) => netsparkAxios.get(exportSowDataUrl, {
  params: {
    page: exportParams.page,
    page_size: exportParams.pageSize,
    customerId: exportParams.id,
    orderId: exportParams.additionalIds,
  },
  responseType: 'blob',
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export const getSalesTSDList = () => netsparkAxios.get<ISalesTSDItem[]>(getTSDListUrl);

export const getProductCategoriesList = (
  params?: IProductCategoriesParams,
) => netsparkAxios.get<IProductCategory[]>(getProductCategoriesListUrl, { params });

export const getCarriersList = (
  params: ICarriersParams,
) => netsparkAxios.get<ICarrier[]>(getCarriersListUrl, { params });
