import dayjs, { ManipulateType } from 'dayjs';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { dateFormat } from 'pages/Dashboard/shared/FiltersByPeriod/constants';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';

const calculatePeriod = (periodMapping, selectedPeriod): TPeriod => {
  const today = dayjs();
  let periodInfo = { value: 0, unit: 'month' };

  switch (selectedPeriod) {
    case PERIOD_SELECT.CURRENT_MONTH: {
      periodInfo = { value: today.date() - 1, unit: 'day' };
      break;
    }
    default: {
      periodInfo = periodMapping[selectedPeriod] || periodInfo;
    }
  }

  const { value, unit } = periodInfo;

  const dateFrom = today.subtract(value, unit as ManipulateType);

  return {
    dateFrom: dateFrom.format(dateFormat),
    dateTo: today.format(dateFormat),
  };
};

export default calculatePeriod;
