import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store';
import { createCustomersDraftAction } from 'core/actions/Customers/CreateCustomerDraft/actions';
import getUsersAction from 'core/actions/Customers/GetUsers/actions';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { getSellersParams, getDraftNotifierMessage } from 'pages/Customers/CustomersCreate/constants';
import schema from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateFormSchema';

const useInitialFormData = () => {
  const { showErrorNotifier } = useNotifiers();
  const dispatch = useAppDispatch();

  const { seller, id } = useAppSelector((state) => state.customers.draft.draftFields);
  const isLoadingDraft = useAppSelector((state) => state.customers.draft.isLoading);
  const isLoadingUsers = useAppSelector((state) => state.customers.users.isLoading);
  const isLoading = isLoadingDraft || isLoadingUsers;

  const getDraftFields = async () => {
    try {
      await Promise.all([
        dispatch(createCustomersDraftAction()),
        dispatch(getUsersAction(getSellersParams)),
      ]);
    } catch (error) {
      showErrorNotifier(getDraftNotifierMessage.error, error);
    }
  };

  useEffect(() => {
    getDraftFields();
  }, []);

  const initialValues = useMemo(() => ({
    ...schema.getDefault(),
    id,
  }), [id, seller]);

  return {
    initialValues,
    seller,
    id,
    isLoading,
  };
};

export default useInitialFormData;
