import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import EditRowSidebar from 'pages/WirelineEngagements/shared/EditRowSidebar';
import { EngagementType } from 'pages/InsideSales/model';
import {
  dynamicActiveNumbersProductSchemaName,
  editActiveNumbersTitle,
  getActiveNumbersItemNotifierMessage,
  patchActiveNumbersItemNotifierMessage, viewActiveNumbersTitle,
} from '@EngagementsActiveNumbers/constants';
import { alwaysActiveFieldsForPM } from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/EditRowSidebarForm/constants';
import { getSalesActiveNumbersItemAction, patchSalesActiveNumbersItemAction } from 'core/actions/Sales/SalesActiveNumbersItem';

import './EditRowSidebarForm.scss';

const EditRowSidebarForm = () => {
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const { getTableData } = useTableContext();

  const {
    activeRowId, cleanActiveRow, activeRowMode,
    secondarySidebarMode, setSecondarySidebarMode,
  } = useTableActiveRowContext();
  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const salesActiveNumbersItem = useAppSelector(
    (state) => state.wirelineEngagements.salesActiveNumbersItem.result,
  );
  const isLoadingActiveNumbersItem = useAppSelector(
    (state) => state.wirelineEngagements.salesActiveNumbersItem.isLoading,
  );

  const salesActiveNumbersItemKeys = salesActiveNumbersItem
    ? Object.keys(salesActiveNumbersItem)
    : [];

  const isCanceled = salesActiveNumbersItem.pmEngagementType === EngagementType.CANCELED;
  const disabledFields = salesActiveNumbersItemKeys.reduce((acc, str) => {
    acc[str] = isCanceled && !alwaysActiveFieldsForPM.includes(str);
    return acc;
  }, {});

  const onSubmit = (params, { setErrors, resetForm }) => {
    if (!activeRowId || !isEditMode) return;

    dispatch(patchSalesActiveNumbersItemAction({ id: activeRowId, params }))
      .unwrap()
      .then(() => {
        cleanActiveRow();
        showSuccessNotifier(patchActiveNumbersItemNotifierMessage.success);
        getTableData();
        resetForm();
      })
      .catch((e) => {
        setErrors(e?.response?.data);
        showErrorNotifier(patchActiveNumbersItemNotifierMessage.error, e);
      });
  };

  useEffect(() => {
    if (!activeRowId) return;

    dispatch(getSalesActiveNumbersItemAction(activeRowId))
      .unwrap()
      .catch((e) => showErrorNotifier(getActiveNumbersItemNotifierMessage.error, e));
  }, [activeRowId]);

  return (
    <Formik
      initialValues={salesActiveNumbersItem}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <EditRowSidebar
          disabledFields={disabledFields}
          isViewMode={isViewMode}
          cleanEditRow={cleanActiveRow}
          schemaName={dynamicActiveNumbersProductSchemaName}
          editRowId={(isEditMode || isViewMode) ? activeRowId : undefined}
          title={isEditMode ? editActiveNumbersTitle : viewActiveNumbersTitle}
          isLoadingItem={isLoadingActiveNumbersItem}
          secondarySidebarMode={secondarySidebarMode}
          setSecondarySidebarMode={setSecondarySidebarMode}
          allowedFields={salesActiveNumbersItemKeys}
        />
      </Form>
    </Formik>
  );
};

export default EditRowSidebarForm;
