import { useIsUserType } from 'core/hooks/useIsUserType';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserType, UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';

const { INSIDE_SALES, SELLER } = UserTeamRoleTeamDepartment;

const useInsideSalesListPermissions = () => {
  const isAdmin = useIsUserType(UserType.ADMIN);
  const isInsideSales = useIsTeamRolesDepartmentMember(INSIDE_SALES);
  const isSeller = useIsTeamRolesDepartmentMember(SELLER);

  const isRequestAcceptedEnabled = isInsideSales || isAdmin;

  const isFieldViewEnabled = !isSeller;
  const isCreateEnabled = isAdmin || isSeller;
  const isCancelRequestEnabled = isAdmin || isSeller;
  const isAbleToMarkRequestAsCompleted = isAdmin || isSeller;
  const isRowActionEnabled = isAdmin || isSeller || isInsideSales;

  return {
    isCreateEnabled,
    isFieldViewEnabled,
    isRowActionEnabled,
    isCancelRequestEnabled,
    isRequestAcceptedEnabled,
    isAbleToMarkRequestAsCompleted,
  };
};

export default useInsideSalesListPermissions;
