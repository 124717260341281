import { combineReducers } from '@reduxjs/toolkit';
import getRetentionBookingDataReducer from 'core/actions/Sales/GetRetentionBookingData/reducer';
import getEngagementsTotalOrdersReducer
  from '@dashboardWirelineSellerDepartment//GetEngagementsTotalOrders/reducer';
import getEngagementsOrdersByPeriodReducer
  from '@dashboardWirelineSellerDepartment/GetEngagementsOrdersByPeriod/reducer';
import getEngagementsOrdersMrcReducer from '@dashboardWirelineSellerDepartment/GetEngagementsOrdersMrc/reducer';
import getEngagementsRevenueReducer from '@dashboardWirelineSellerDepartment/GetEngagementsRevenue/reducer';
import getProjectAcceptanceReducer
  from '@dashboardWirelinePMDepartment/GetProjectAcceptance/reducer';
import getProjectStatusReducer from '@dashboardWirelinePMDepartment/GetProjectStatus/reducer';
import getGeneralEngagementReducer from '@dashboardWirelinePMDepartment/GetGeneralEngagement/reducer';
import { getTransactionalOrdersReducer } from '@dashboardWirelineContractSpecialistDepartment/GetTransactionalOrders';
import { getOpenRequestsReducer } from '@dashboardWirelineContractSpecialistDepartment/GetOpenRequests';
import { getRequestTypeReducer } from '@dashboardWirelineBillingDepartment/GetRequestType';
import { getBillingEngagementStatusReducer } from '@dashboardWirelineBillingDepartment/GetBillingEngagementStatus';
import { getTechnicalOverlayReducer } from '@dashboardWirelineSellerDepartment/TechnicalOverlay/GetTechnicalOverlay';
import { getBookingSellerDataReducer } from '@dashboardWirelineSellerDepartment/GetBookingSellerData';
import { getBookingReportDataReducer } from '@dashboardWirelineSellerDepartment/GetBookingReportData';
import { getBookingCSDataReducer } from '@dashboardWirelineContractSpecialistDepartment/GetBookingCSData';
import { getBookingTableDataReducer } from '@dashboardWirelineSellerDepartment/GetBookingTableData';
import getInsideSalesBookingDataReducer from '@dashboardWirelineInsideSalesDepartment/GetInsideSalesTableData/reducer';
import getEscalationSupportDataReducer from '@dashboardEscalationSupport/EscalationSupportTable/GetEscalationSupport/reducer';
import { escalationSupportItemReducer } from 'core/actions/Sales/EscalationSupportItem';
import { getRenewedEngagementsReducer } from '@dashboardWirelineRetentionDepartment/GetRenewedEngagements';
import { getDeclinedEngagementsReducer } from '@dashboardWirelineRetentionDepartment/GetDeclinedEngagements';
import { getInProgressEngagementsReducer } from '@dashboardWirelineRetentionDepartment/GetInProgressEngagements';
import {
  getTotalEngagementsReducer,
} from '@dashboardWirelineRetentionDepartment/GetTotalEngagements/GetTotalEngagements';
import { getRequestStatusReducer } from '@dashboardWirelineInsideSalesDepartment/GetRequestStatus';
import { getInsideSalesUsersReducer } from '@dashboardWirelineInsideSalesDepartment/GetInsideSalesUsers';
import {
  getEscalationSupportTotalReducer,
} from '@dashboardEscalationSupport/EscalationSupportCharts/Charts/Total/GetEscalationSupportTotal';
import {
  getEscalationSupportEscalationTypeReducer,
} from '@dashboardEscalationSupport/EscalationSupportCharts/Charts/EscalationType/GetEscalationSupportEscalationType';

const dashboardReducer = combineReducers({
  engagementsByPeriod: getEngagementsOrdersByPeriodReducer,
  engagementsTotals: getEngagementsTotalOrdersReducer,
  engagementsMrc: getEngagementsOrdersMrcReducer,
  engagementsRevenue: getEngagementsRevenueReducer,
  projectAcceptance: getProjectAcceptanceReducer,
  projectStatus: getProjectStatusReducer,
  generalEngagement: getGeneralEngagementReducer,
  bookingSellerData: getBookingSellerDataReducer,
  bookingReportData: getBookingReportDataReducer,
  bookingTableData: getBookingTableDataReducer,
  bookingCSData: getBookingCSDataReducer,
  transactionalOrders: getTransactionalOrdersReducer,
  openRequests: getOpenRequestsReducer,
  requestType: getRequestTypeReducer,
  billingEngagementStatus: getBillingEngagementStatusReducer,
  technicalOverlay: getTechnicalOverlayReducer,
  escalationSupport: getEscalationSupportDataReducer,
  escalationSupportItem: escalationSupportItemReducer,
  totalEngagements: getTotalEngagementsReducer,
  renewedEngagements: getRenewedEngagementsReducer,
  declinedEngagements: getDeclinedEngagementsReducer,
  inProgressEngagements: getInProgressEngagementsReducer,
  retentionBookingData: getRetentionBookingDataReducer,
  requestStatus: getRequestStatusReducer,
  insideSalesUsers: getInsideSalesUsersReducer,
  insideSalesBookingData: getInsideSalesBookingDataReducer,
  escalationSupportTotal: getEscalationSupportTotalReducer,
  escalationSupportType: getEscalationSupportEscalationTypeReducer,
});

export default dashboardReducer;
