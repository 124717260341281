// eslint-disable-next-line no-unused-vars
import { TBookingReportPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import {
  getBookingReportDataRequest,
  getBookingReportDataSuccess,
  getBookingReportDataFailure,
} from './GetBookingReportData';

import getBookingReportData from './api.service';

const getBookingReportDataAction = (
  controller,
  params: {
      page: number;
      page_size: number;
      search: string;
      ordering?: string | undefined;
    },
  period?: TBookingReportPeriod) => async (dispatch) => {
  const { signal } = controller;

  try {
    dispatch(getBookingReportDataRequest());

    const combinedParams = {
      ...params,
      ...period,
    };

    const { data } = await getBookingReportData(signal, combinedParams);
    dispatch(getBookingReportDataSuccess(data));
  } catch (error) {
    dispatch(getBookingReportDataFailure(error));
  }
};

export default getBookingReportDataAction;
