import React from 'react';
import Table from 'shared/Table';
import { Typography } from '@mui/material';
import { useAppSelector } from 'core/store';
import { TRow } from 'shared/Table/Table.models';

interface IBookingTableProps {
  isLoadingTableFiltersData: boolean
}

const BookingTable: React.FC<IBookingTableProps> = ({
  isLoadingTableFiltersData,
}) => {
  const isLoadingData = useAppSelector((state) => state.dashboard.bookingCSData.isLoading);
  const bookingDataCount = useAppSelector((state) => state.dashboard.bookingCSData.count);
  const bookingData = useAppSelector((state) => state.dashboard.bookingCSData.results);

  const isLoading = isLoadingData || isLoadingTableFiltersData;

  return (
    <Table
      showToolbar
      showColumnFilters
      enableStickyHeader
      isLoading={isLoading}
      showGlobalFilter={false}
      rowCount={bookingDataCount}
      rows={bookingData as unknown as TRow[]}
      muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
      toolBarStartContent={(
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold' }}
        >
          Booking Table
        </Typography>
      )}
    />
  );
};

export default BookingTable;
