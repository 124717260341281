import { combineReducers } from '@reduxjs/toolkit';

import salesSowReducer from 'core/actions/Sales/GetSalesSow/reducer';
import { salesSowItemReducer } from 'core/actions/Sales/SalesSowItem';
import salesSowMetaReducer from 'core/actions/Sales/GetSalesSowMeta/reducer';
import { requestOrdersReducer } from 'core/actions/Sales/RequestOrders';
import { getProductCategoriesReducer } from 'core/actions/Sales/GetProductCategories';
import { salesSowOrderFormReducer } from 'core/actions/Sales/SalesSowOrderForm/GetSalesSowOrderForm';
import getEngagementsLisReducer from 'pages/WirelineEngagements/WirelineEngagementsMainPage/WirelineEngagementsList/GetEngagementsList/reducer';
import commentsReducer from 'pages/WirelineEngagements/WirelineEngagementsComments/reducer';
import attachmentsReducer from 'pages/WirelineEngagements/WirelineEngagementsAttachments/reducer';
import { getContractBundlesReducer } from '@EngagementsSowData/GetContractBundles';
import getActiveNumbersReducer from 'core/actions/Sales/GetSalesActiveNumbers/reducer';
import { salesActiveNumbersItemReducer } from 'core/actions/Sales/SalesActiveNumbersItem';
import getRenewalSowReducer from 'core/actions/Sales/GetSalesRenewalSow/reducer';
import { salesRenewalSowItemReducer } from 'core/actions/Sales/SalesRenewalSowItem';
import { salesOrderCommentsReducer } from 'core/actions/Sales/OrderComments';
import { getTSDsReducer } from 'core/actions/Sales/GetTSDs';
import { getCarriersReducer } from 'core/actions/Sales/GetCarriers';
import { createProductsReducer } from 'core/actions/Sales/CreateProduct';
import { salesActiveNumbersMetaReducer } from 'core/actions/Sales/GetSalesActiveNumbersMeta';

const wirelineEngagementsReducer = combineReducers({
  list: getEngagementsLisReducer,
  salesSow: salesSowReducer,
  salesSowItem: salesSowItemReducer,
  salesSowOrderForm: salesSowOrderFormReducer,
  requestOrders: requestOrdersReducer,
  salesSowMeta: salesSowMetaReducer,
  comments: commentsReducer,
  orderComments: salesOrderCommentsReducer,
  attachments: attachmentsReducer,
  contractBundles: getContractBundlesReducer,
  activeNumbers: getActiveNumbersReducer,
  salesActiveNumbersItem: salesActiveNumbersItemReducer,
  renewalSow: getRenewalSowReducer,
  renewalSowItem: salesRenewalSowItemReducer,
  productCategories: getProductCategoriesReducer,
  TSDs: getTSDsReducer,
  carriers: getCarriersReducer,
  createProducts: createProductsReducer,
  salesActiveNumbersMeta: salesActiveNumbersMetaReducer,
});

export default wirelineEngagementsReducer;
