import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@mui/material';
import { useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import { useUserPermissions } from '@EngagementsActiveNumbers/useUserPermissions';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { engagementAcceptedTitle } from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/constants';
import EngagementAcceptedModal from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAcceptedModal';
import EngagementAcceptedMenuButton from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAcceptedMenuButton';

const { BILLING_SPECIALIST, PROJECT_MANAGER } = UserTeamRoleTeamDepartment;

const ActiveNumbersEngagementAccepted = () => {
  const { setRowSelection } = useTableContext();

  const [actionUserRole, setActionUserRole] = useState('');
  const [isEngagementAcceptedModalOpen, setIsEngagementAcceptedModalOpen] = useState(false);

  const activeNumbersData = useAppSelector(
    (state) => state.wirelineEngagements.activeNumbers.results,
  );

  const selectedActiveNumbers = useTableSelectedRows<IActiveNumbersItem>(
    { rows: activeNumbersData },
  );

  const { isAdmin, isBillingSpecialist } = useUserPermissions();

  const isDisabledEngagementAccepted = isEmpty(selectedActiveNumbers);

  const onOpenEngagementAcceptedModal = (roleUser: string) => {
    setActionUserRole(roleUser);
    setIsEngagementAcceptedModalOpen(true);
  };

  const onCloseEngagementAcceptedModal = () => {
    setIsEngagementAcceptedModalOpen(false);
    setRowSelection({});
    setActionUserRole('');
  };

  const onOpenEngagementAcceptedModalNonAdminRole = () => {
    const roleUser = isBillingSpecialist ? BILLING_SPECIALIST : PROJECT_MANAGER;

    onOpenEngagementAcceptedModal(roleUser);
  };

  return (
    <div className="active-number__engagement-acceptance">
      {!isAdmin && (
      <Button
        startIcon={<CheckCircleOutlineIcon />}
        disabled={isDisabledEngagementAccepted}
        onClick={onOpenEngagementAcceptedModalNonAdminRole}
      >
        {engagementAcceptedTitle}
      </Button>
      )}
      {isAdmin && (
        <EngagementAcceptedMenuButton
          onSelect={onOpenEngagementAcceptedModal}
          isDisabled={isDisabledEngagementAccepted}
          selectedActiveNumbers={selectedActiveNumbers}
        />
      )}
      <EngagementAcceptedModal
        actionUserRole={actionUserRole}
        isModalOpen={isEngagementAcceptedModalOpen}
        onCloseModal={onCloseEngagementAcceptedModal}
        selectedActiveNumbers={selectedActiveNumbers}
      />
    </div>
  );
};

export default ActiveNumbersEngagementAccepted;
