/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOpenapiSchema } from 'core/api/openapi.api';
import { IOpenapiSchema, IOpenapiSchemasPaths } from 'core/models/openapi.models';

export interface IState {
  schemas: IOpenapiSchema;
  paths: IOpenapiSchemasPaths;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: IState = {
  schemas: {},
  paths: {},
  isLoading: true,
  error: null,
};

export const getOpenapiSchemaAction = createAsyncThunk(
  'getOpenapiSchemaAction',
  async () => {
    const { data } = await getOpenapiSchema();
    return data;
  });

const openapiSchemasSlice = createSlice({
  name: 'openapiSchemas',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOpenapiSchemaAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOpenapiSchemaAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.schemas = action.payload.components.schemas;
        state.paths = action.payload.paths;
        state.error = null;
      })
      .addCase(getOpenapiSchemaAction.rejected, (state, action) => {
        state.isLoading = false;
        state.schemas = {};
        state.paths = {};
        state.error = action.error.message;
      });
  },
});

export const openapiSchemasReducer = openapiSchemasSlice.reducer;
