import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { IBookingDataItem } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';
import {
  getBookingSellerDataRequest,
  getBookingSellerDataSuccess,
  getBookingSellerDataFailure,
  clearBookingSellerDataAction,
} from '@dashboardWirelineSellerDepartment/GetBookingSellerData';
import {
  getBookingReportDataRequest,
  getBookingReportDataSuccess,
  getBookingReportDataFailure,
  clearBookingReportDataAction,
} from '@dashboardWirelineSellerDepartment/GetBookingReportData';

export interface IState {
  count: number
  isLoading: boolean
  error: object | null
  results: IBookingDataItem[]
}

const initialState: IState = {
  count: 0,
  results: [],
  error: null,
  isLoading: true,
};

const BookingTableDataSlice = createSlice({
  name: 'getBookingTableData',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        isAnyOf(
          getBookingSellerDataRequest,
          getBookingReportDataRequest,
        ),
        (state) => ({
          ...state,
          isLoading: true,
        }),
      )
      .addMatcher(
        isAnyOf(
          getBookingSellerDataSuccess,
          getBookingReportDataSuccess,
        ),
        (state, action) => ({
          ...state,
          ...action.payload,
          isLoading: false,
        }),
      )
      .addMatcher(
        isAnyOf(
          getBookingSellerDataFailure,
          getBookingReportDataFailure,
        ),
        (state, action) => ({
          ...state,
          results: [],
          isLoading: false,
          error: action.payload,
        }),
      )
      .addMatcher(
        isAnyOf(
          clearBookingSellerDataAction,
          clearBookingReportDataAction,
        ),
        () => initialState,
      );
  },
});

export const { clear: clearBookingTableDataAction } = BookingTableDataSlice.actions;

export const getBookingTableDataReducer = BookingTableDataSlice.reducer;
