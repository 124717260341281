import React from 'react';
import get from 'lodash/get';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';

import './EscalationSupportInfo.scss';

interface IEscalationSupportInfoProps {
  escalationSupportRow: ISowDataListItem | IRenewalSowDataItem | IActiveNumbersItem
}

const EscalationSupportInfo: React.FC<IEscalationSupportInfoProps> = ({
  escalationSupportRow,
}) => {
  const location = (
    get(escalationSupportRow, 'customerLocation', '')
    || get(escalationSupportRow, 'location', '')
  ) as string;
  const product = get(escalationSupportRow, 'product', '');
  const projectManagerFullName = get(escalationSupportRow, 'projectManagerFullname', '');
  const customerName = get(escalationSupportRow, 'customerCompanyName', '') || get(escalationSupportRow, 'customerName', '');

  return (
    <div className="escalation-support__info-wrap">
      <div className="escalation-support__info">
        <div className="escalation-support__info-name">Location</div>
        <div className="escalation-support__info-value">
          {location}
        </div>
      </div>
      <div className="escalation-support__info">
        <div className="escalation-support__info-name">Product</div>
        <div className="escalation-support__info-value">
          {product}
        </div>
      </div>
      <div className="escalation-support__info">
        <div className="escalation-support__info-name">Project Manager</div>
        <div className="escalation-support__info-value">
          {projectManagerFullName}
        </div>
      </div>
      <div className="escalation-support__info">
        <div className="escalation-support__info-name">Customer Name</div>
        <div className="escalation-support__info-value">
          {customerName}
        </div>
      </div>
    </div>
  );
};

export default EscalationSupportInfo;
