import { IEngagementsListItem } from 'pages/WirelineEngagements/WirelineEngagements.models';
import {
  GET_ENGAGEMENTS_LIST_REQUEST,
  GET_ENGAGEMENTS_LIST_SUCCESS,
  GET_ENGAGEMENTS_LIST_FAILURE,
} from './actionsTypes';

interface IGetEngagementsListState {
  results: IEngagementsListItem[];
  isLoading: boolean;
  count: number;
  errors: object;
}

const initialState: IGetEngagementsListState = {
  isLoading: true,
  count: 0,
  results: [],
  errors: {},
};

const getEngagementsLisReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_ENGAGEMENTS_LIST_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_ENGAGEMENTS_LIST_SUCCESS) {
    return {
      ...state,
      ...data,
      isLoading: false,
    };
  }

  if (type === GET_ENGAGEMENTS_LIST_FAILURE) {
    return {
      ...state,
      isLoading: false,
      results: [],
      error,
    };
  }

  return state;
};
export default getEngagementsLisReducer;
