import React from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import FormikTextareaField from 'shared/FormikField/FormikTextareaField';
import CustomModal from 'shared/CustomModal';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import FormikSelectField from 'shared/FormikField/FormikSelectField/FormikSelectField';
import { escalationTypeOptions } from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/constants';
import EscalationSupportInfo from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/EscalationSupportInfo/index';

import './EscalationSupporModal.scss';

interface IEscalationSupportModalProps {
  cleanEscalationSupportRow: () => void;
  escalationSupportRowId: string | number | undefined | null;
  escalationSupportRow: ISowDataListItem | IRenewalSowDataItem | IActiveNumbersItem;
}

const EscalationSupportModal: React.FC<IEscalationSupportModalProps> = ({
  escalationSupportRowId, cleanEscalationSupportRow, escalationSupportRow,
}) => {
  const { submitForm } = useFormikContext();

  const closeEscalationSupportModal = () => {
    cleanEscalationSupportRow();
  };

  return (
    <CustomModal
      title="Escalation support"
      isModalOpen={!!escalationSupportRowId}
      onClickConfirm={submitForm}
      onCloseModal={closeEscalationSupportModal}
      confirmButtonText="Send"
      areButtonsVisible
      maxWidth="504px"
      isCancelButtonVisible={false}
      isConfirmDialog
    >
      <Grid
        className="escalation-support__modal"
        container
        item
        rowSpacing={3}
      >
        <FormikSelectField
          label="Escalation Type"
          fieldName="type"
          options={escalationTypeOptions}
          fullWidth
          required
        />
        <EscalationSupportInfo
          escalationSupportRow={escalationSupportRow}
        />
        <FormikTextareaField
          fieldName="details"
        />
      </Grid>
    </CustomModal>
  );
};

export default EscalationSupportModal;
