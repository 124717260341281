import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import { UserTeamRoleName, UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';

import { useIsUserType } from 'core/hooks/useIsUserType';

const {
  BILLING_SPECIALIST, CONTRACT_SPECIALIST, RETENTION_MANAGER, PROJECT_MANAGER, SELLER,
} = UserTeamRoleTeamDepartment;

export const useUserPermission = () => {
  const isAdmin = useIsUserType(UserType.ADMIN);
  const isSeller = useIsTeamRolesDepartmentMember(SELLER);
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isTechnicalOverlay = useIsUserTeamRoleName(UserTeamRoleName.TECHNICAL_OVERLAY);

  const isAvailableForBillingCategory = isAdmin || isBillingSpecialist;
  const isAvailableForGeneralCategory = isAdmin
    || isSeller
    || isTechnicalOverlay
    || isContractSpecialist
    || isProjectManager
    || isRetentionManager;

  return {
    isAvailableForBillingCategory,
    isAvailableForGeneralCategory,
  };
};
