import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useAppDispatch } from 'core/store';
import { HTTPService } from 'core/services';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import { TPeriod, TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { notifierMessage } from '@dashboardWirelineSellerDepartment/constants';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import getEngagementsOrdersMrcAction from '@dashboardWirelineSellerDepartment/GetEngagementsOrdersMrc/actions';
import getEngagementsRevenueAction from '@dashboardWirelineSellerDepartment/GetEngagementsRevenue/actions';
import getEngagementsOrdersByPeriodAction from '@dashboardWirelineSellerDepartment/GetEngagementsOrdersByPeriod/actions';
import getEngagementsTotalOrdersAction from '@dashboardWirelineSellerDepartment/GetEngagementsTotalOrders/actions';
import useNotifiers from 'shared/Notifier/UseNotifiers';

interface ISellerDepartmentContext {
  getDashboardData: TSetPeriod;
  period: TPeriod;
}

interface ISellerDepartmentProviderProps {
    children: React.ReactNode;
}

const SellerDepartmentContext = createContext<
    ISellerDepartmentContext
>({} as ISellerDepartmentContext);

let engagementsTotalsController = HTTPService.getController();
let engagementsByPeriodController = HTTPService.getController();
let engagementsMrcController = HTTPService.getController();
const engagementsRevenueController = HTTPService.getController();

const SellerDepartmentProvider: React.FC<ISellerDepartmentProviderProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  const { showErrorNotifier } = useNotifiers();

  const defaultPeriod = calculatePeriod(periodMapping, PERIOD_SELECT.CURRENT_MONTH);
  const [period, setPeriod] = useState(defaultPeriod);

  const cancelGetRevenueRequest = () => {
    HTTPService.cancelRequest(engagementsRevenueController);
  };

  const cancelGetEngagementTotalsRequest = () => {
    HTTPService.cancelRequest(engagementsTotalsController);
  };

  const cancelGetEngagementByPeriodRequest = () => {
    HTTPService.cancelRequest(engagementsByPeriodController);
  };

  const cancelGetEngagementMrcRequest = () => {
    HTTPService.cancelRequest(engagementsMrcController);
  };

  const getEngagementsRenevue = async (newPeriod) => {
    cancelGetRevenueRequest();

    try {
      engagementsMrcController = HTTPService.getController();
      await dispatch(getEngagementsRevenueAction(engagementsMrcController, newPeriod));
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsRevenue.error, e);
    }
  };

  const getEngagementsMrc = async (newPeriod) => {
    cancelGetEngagementMrcRequest();

    try {
      engagementsMrcController = HTTPService.getController();
      await dispatch(getEngagementsOrdersMrcAction(engagementsMrcController, newPeriod));
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsMrc.error, e);
    }
  };

  const getEngagementsByPeriod = async (newPeriod) => {
    cancelGetEngagementByPeriodRequest();

    try {
      engagementsByPeriodController = HTTPService.getController();
      await dispatch(getEngagementsOrdersByPeriodAction(engagementsByPeriodController, newPeriod));
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsTotals.error, e);
    }
  };

  const getEngagementsTotals = async (newPeriod) => {
    cancelGetEngagementTotalsRequest();

    try {
      engagementsTotalsController = HTTPService.getController();

      await dispatch(getEngagementsTotalOrdersAction(engagementsTotalsController, newPeriod));
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsTotals.error, e);
    }
  };

  const getDashboardData = (newPeriod) => {
    getEngagementsTotals(newPeriod);
    getEngagementsByPeriod(newPeriod);
    getEngagementsMrc(newPeriod);
    getEngagementsRenevue(newPeriod);
    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelGetEngagementTotalsRequest();
    cancelGetEngagementByPeriodRequest();
    cancelGetEngagementMrcRequest();
    cancelGetRevenueRequest();
  };

  useEffect(() => {
    getDashboardData(period);

    return () => cancelRequests();
  }, []);

  const value = useMemo(() => ({
    getDashboardData,
    period,
  }), [period]);

  return (
    <SellerDepartmentContext.Provider value={value}>
      {children}
    </SellerDepartmentContext.Provider>
  );
};

export const useSellerDepartmentContext = () => useContext(SellerDepartmentContext);

export default SellerDepartmentProvider;
