import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import Loader from 'shared/Loader';
import { useAppSelector } from 'core/store';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import FormikFieldsFromSchema from 'pages/WirelineEngagements/shared/FormikFieldsFromSchema';
import { controlledFieldsConfigs } from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields/constants';

interface IEditRowSidebarFieldsProps {
  editRowId: string | number | undefined | null;
  isLoadingItem: boolean;
  isDisabled?: boolean;
  disabledFields?: { [key: string]: boolean }
  schemaName: string;
  allowedFields: string[];
}

const EditRowSidebarFields: React.FC<IEditRowSidebarFieldsProps> = ({
  editRowId, schemaName, isLoadingItem,
  isDisabled, disabledFields, allowedFields,
}) => {
  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const retrieveSchema = useMemo(() => {
    const parsedSchema = parseOpenapiSchema(openApiSchemas, schemaName);
    return parsedSchema.filter((
      schemaItem,
    ) => allowedFields.includes(schemaItem.fieldName));
  }, [schemaName, openApiSchemas, allowedFields]);

  if (!editRowId) {
    return null;
  }

  if (isLoadingSchemas) {
    return (
      <Loader className="edit-row-sidebar__loader" />
    );
  }

  return (
    <Box className="edit-row-sidebar__fields">
      {isLoadingItem && <Loader />}
      <Grid
        container
        item
        rowSpacing={3}
      >
        <FormikFieldsFromSchema
          retrieveSchema={retrieveSchema}
          isDisabled={isDisabled as boolean}
          disabledFields={disabledFields}
          controlledFieldsConfigs={controlledFieldsConfigs}
        />
      </Grid>
    </Box>
  );
};

EditRowSidebarFields.defaultProps = {
  isDisabled: false,
  disabledFields: {},
};

export default EditRowSidebarFields;
