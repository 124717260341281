export enum COMMENTS_ORDER_CATEGORY {
  BILLING = 'BILLING',
  GENERAL = 'GENERAL',
}

export const commentsTabsConfigs = [{
  name: 'General comments', category: COMMENTS_ORDER_CATEGORY.GENERAL,
}, {
  name: 'Billing', category: COMMENTS_ORDER_CATEGORY.BILLING,
}];
