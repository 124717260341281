import dayjs from 'dayjs';
import { dateFormatYYYYMM, dateFormatMMMYYYY } from 'core/constants';

export const getReportPeriodOptions = ({
  months, withCurrentMonth,
}: {
  months: number, withCurrentMonth?: boolean
}) => {
  const lastMonth = withCurrentMonth ? dayjs() : dayjs().subtract(1, 'month');

  return [...Array(months)].map((_, index) => {
    const date = lastMonth.subtract(index, 'month');
    return { label: date.format(dateFormatMMMYYYY), value: date.format(dateFormatYYYYMM) };
  });
};
