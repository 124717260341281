import React from 'react';
import Actions from 'shared/Table/Actions';
import { orderActionTitle } from 'core/labels';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IEscalationSupportItem, Status } from '@dashboardEscalationSupport/EscalationSupportTable/model';

const EscalationSupportRowActions = ({ row }) => {
  const {
    activeRowId,
    activeRowMode,
    setActiveRowEdit,
  } = useTableActiveRowContext<IEscalationSupportItem>();

  const isCompleted = row?.status === Status.CLOSED;

  return (
    <Actions<IEscalationSupportItem>
      row={row}
      activeRowId={activeRowId}
      activeRowMode={activeRowMode}
      setActiveRowEdit={setActiveRowEdit}
      isActiveButtonDisabled={isCompleted}
      moreButtonTooltipTitle={orderActionTitle}
    />
  );
};

export default EscalationSupportRowActions;
