import React, { useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import {
  map, get, set, omit, cloneDeep,
} from 'lodash';
import {
  editSowDataTitle,
  getSowItemMessage,
  viewSowDataTitle,
  patchSalesSowItemActionMessage,
} from '@EngagementsSowData/constants';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { getSalesSowItemAction, patchSalesSowItemAction } from 'core/actions/Sales/SalesSowItem';
import { useTableContext } from 'shared/Table/TableContext';
import EditRowSidebar from 'pages/WirelineEngagements/shared/EditRowSidebar';
import { controlledFieldsConfigs } from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields/constants';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { alwaysActiveFieldsForPM } from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/EditRowSidebarForm/constants';
import { dynamicProductSchemaName } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsSowData/constants';
import { EngagementType } from 'pages/InsideSales/model';
import './EditRowSidebarForm.scss';

const EditRowSidebarForm = () => {
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();
  const dispatch = useAppDispatch();
  const { getTableData } = useTableContext();

  const {
    activeRowMode, activeRowId, cleanActiveRow,
    setSecondarySidebarMode, secondarySidebarMode,
    activeRow,
  } = useTableActiveRowContext<ISowDataListItem>();

  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);
  const salesSowItem = useAppSelector((state) => state.wirelineEngagements.salesSowItem.result);

  const salesSowItemKeys = salesSowItem ? Object.keys(salesSowItem) : [];

  const isLoadingSowItem = useAppSelector(
    (state) => state.wirelineEngagements.salesSowItem.isLoading,
  );

  const initialFormValues = useMemo(() => {
    const existingServiceFromActiveRow = activeRow?.existingService;
    const existingServiceExistInSalesSowData = 'existingService' in salesSowItem;

    return existingServiceExistInSalesSowData
      ? salesSowItem
      : { ...salesSowItem, existingService: existingServiceFromActiveRow };
  }, [activeRow, salesSowItem]);
  const isCanceled = initialFormValues.pmEngagementType === EngagementType.CANCELED;
  const disabledFields = salesSowItemKeys.reduce((acc, str) => {
    acc[str] = isCanceled && !alwaysActiveFieldsForPM.includes(str);
    return acc;
  }, {});

  const clearParamsFromAdditionalData = (params) => {
    const existingServiceExistInSalesSowData = 'existingService' in salesSowItem;

    return existingServiceExistInSalesSowData ? params : omit(params, ['existingService']);
  };

  const onSubmit = (params, { setErrors, resetForm }) => {
    if (!activeRowId || !isEditMode) return;

    const formattedParams = cloneDeep(params);

    map(
      controlledFieldsConfigs,
      ({ fieldName, switchFieldName, switchEnabledValues }) => {
        const switchFieldValue = formattedParams[switchFieldName];

        const isDisabled = !switchEnabledValues.includes(switchFieldValue);

        if (isDisabled) {
          set(formattedParams, fieldName, get(salesSowItem, fieldName));
        }
      },
    );

    const clearedParamsFromAddtitinalData = clearParamsFromAdditionalData(formattedParams);

    dispatch(patchSalesSowItemAction({ id: activeRowId, params: clearedParamsFromAddtitinalData }))
      .unwrap()
      .then(() => {
        cleanActiveRow();
        setSecondarySidebarMode(null);
        showSuccessNotifier(patchSalesSowItemActionMessage.success);
        getTableData();
        resetForm();
      })
      .catch((e) => {
        setErrors(e?.response?.data);
        showErrorNotifier(patchSalesSowItemActionMessage.error, e);
      });
  };

  useEffect(() => {
    if (!activeRowId || !(isViewMode || isEditMode)) return;

    dispatch(getSalesSowItemAction(activeRowId))
      .unwrap()
      .catch((e) => showErrorNotifier(getSowItemMessage.error, e));
  }, [activeRowId]);

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialFormValues}
    >
      <Form>
        <EditRowSidebar
          isViewMode={isViewMode}
          disabledFields={disabledFields}
          schemaName={dynamicProductSchemaName}
          editRowId={(isEditMode || isViewMode) ? activeRowId : undefined}
          cleanEditRow={cleanActiveRow}
          title={isEditMode ? editSowDataTitle : viewSowDataTitle}
          isLoadingItem={isLoadingSowItem}
          setSecondarySidebarMode={setSecondarySidebarMode}
          secondarySidebarMode={secondarySidebarMode}
          allowedFields={salesSowItemKeys}
        />
      </Form>
    </Formik>
  );
};

export default EditRowSidebarForm;
