import { isNull } from 'lodash';
import { attLabel } from 'core/labels';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem, StateEnum } from '@EngagementsSowData/sowData.model';
import { sellerRequestTypeValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import { BoolEnum } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';

const { NO, YES } = BoolEnum;
const { EXISTING } = StateEnum;

export const checkIfOrdersIsAttTSD = (
  sowDataRowsData: ISowDataListItem[],
) => sowDataRowsData.some((item) => item.tsd !== attLabel);

export const checkIfOrdersRequestTypeIsNonCotractSupport = (
  sowDataRowsData: ISowDataListItem[],
) => sowDataRowsData.some((
  item,
) => item.sellerRequestType !== sellerRequestTypeValues.contractSupport);

export const quoteRequestDisabledConditions = (row: ISowDataListItem) => {
  const {
    bandwidth, existingService, existingContractId, existingAccountNumber,
  } = row;

  const isEmptyBandwidth = !bandwidth;

  const isExistExistingService = 'existingService' in row;

  const isEmptyFieldsIfExistingServiceIsYes = !existingService
   || (existingService === YES && (!existingContractId || !existingAccountNumber));

  return isEmptyBandwidth || (isExistExistingService && isEmptyFieldsIfExistingServiceIsYes);
};

export const contractRequestDisabledMainConditions = (
  row: ISowDataListItem,
) => row.requestContractDatetime
  || (row.existingService === YES && row.useExistingContract === YES);

export const contractRequestAndOrderRequestDisabledConditions = (row: ISowDataListItem) => {
  const {
    term,
    bandwidth,
    existingService,
    existingContractId,
    useExistingContract,
    businessCenterProfile,
    existingAccountNumber,
    businessCenterProfileUsername,
    businessCenterProfileCompanyId,
  } = row;

  const isEmptyFields = isNull(term) || !bandwidth;

  const isExistExistingService = 'existingService' in row;
  const isExistBusinessCenterProfile = 'businessCenterProfile' in row;
  const isUseExistingContractExistAndEmpty = 'useExistingContract' in row && !useExistingContract;

  const isEmptyFieldsIfExistingServiceIsYes = !existingService
   || (existingService === YES
    && (!existingContractId || !existingAccountNumber || isUseExistingContractExistAndEmpty));

  const isEmptyFieldsIfBusinessCenterProfileIsExisting = !businessCenterProfile
   || (businessCenterProfile === EXISTING
   && (!businessCenterProfileCompanyId || !businessCenterProfileUsername));

  return (
    isEmptyFields
    || (isExistExistingService && isEmptyFieldsIfExistingServiceIsYes)
    || (isExistBusinessCenterProfile && isEmptyFieldsIfBusinessCenterProfileIsExisting)
  );
};

export const orderRequestDisabledConditions = ({
  existingService, requestContractDatetime, useExistingContract, requestOrderActionDatetime,
}: ISowDataListItem) => requestOrderActionDatetime
|| (
  (existingService === NO || (existingService === YES && useExistingContract === NO))
  && !requestContractDatetime
);

export const contractProvideAndOrderProvideDisabledConditions = (row: ISowDataListItem) => {
  const {
    oppId,
    newServiceMrc,
    existingService,
    contractSentDate,
    useExistingContract,
    newServiceContractId,
  } = row;

  const isEmptyFields = !newServiceMrc || ('oppId' in row && !oppId);

  const isEmptyFieldsIfExistingServiceIsNoOrEmpty = (
    !existingService
    || existingService === NO
    || (existingService === YES && useExistingContract === NO)
  )
  && (!contractSentDate || !newServiceContractId);

  return isEmptyFields || isEmptyFieldsIfExistingServiceIsNoOrEmpty;
};
