import React from 'react';
import { useFormikContext } from 'formik';
import Loader from 'shared/Loader';
import { Grid, Box } from '@mui/material';
import { getActiveTSDIndex } from 'pages/WirelineEngagements/shared/AddOrderModalForm/utilites';
// eslint-disable-next-line no-unused-vars
import { IFormikProductValues, IProductParams } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';
import AddOrderProductsATTFilter from 'pages/WirelineEngagements/shared/AddOrderModalForm/EngagementsOrderForm/EngagementOrderFormComponents/AddOrderProductsATTFilter';
import AddOrderProductsNonATTFilter from 'pages/WirelineEngagements/shared/AddOrderModalForm/EngagementsOrderForm/EngagementOrderFormComponents/AddOrderProductsNonATTFilter';
import {
  AddOrderTSDField,
  AddProductLocationsField,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/EngagementsOrderForm/EngagementOrderFormFields';

interface IEngagementsFormProductProps {
  attTSDId: number | undefined
  nonATTCategoriesIds: number[]
  getATTProducts: (itemCount: number) => void
  getNonATTProducts: (itemCount: number) => void
}

const EngagementsFormProduct: React.FC<IEngagementsFormProductProps> = ({
  attTSDId, nonATTCategoriesIds, getATTProducts, getNonATTProducts,
}) => {
  const {
    isSubmitting, values,
  } = useFormikContext<IFormikProductValues>();
  const isATT = !values.currentTSD || Number(values?.currentTSD) === attTSDId;

  const currentTSDIndex = getActiveTSDIndex(values);

  return (
    <Box key={values.currentTSD} className="add-order-modal-form__wrapper">
      {isSubmitting && <Loader />}
      <Grid
        item
        container
        xs={12}
        rowSpacing={3}
        flexDirection="column"
      >
        <Grid container item xs={12} columnSpacing={3} direction="row">
          <Grid item xs={4}>
            <AddOrderTSDField itemCount={currentTSDIndex} key="TSDField" />
          </Grid>
          <Grid item xs={8}>
            <AddProductLocationsField itemCount={currentTSDIndex} />
          </Grid>
        </Grid>
        {isATT
          ? (
            <AddOrderProductsATTFilter
              itemCount={currentTSDIndex}
              getProducts={getATTProducts}
            />
          )
          : (
            <AddOrderProductsNonATTFilter
              itemCount={currentTSDIndex}
              getProducts={getNonATTProducts}
              nonATTCategoriesIds={nonATTCategoriesIds}
            />
          )}
      </Grid>
    </Box>
  );
};

export default EngagementsFormProduct;
