// eslint-disable-next-line no-unused-vars
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { sellerRequestTypeValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

interface IOrderRequestTypesNotMatchRolesProps {
  row: IActiveNumbersItem
  isBillingSpecialist: boolean
}

export const isOrderRequestTypesNotMatchRoles = ({
  row, isBillingSpecialist,
}: IOrderRequestTypesNotMatchRolesProps) => {
  const isSellerTypePMforBSRole = isBillingSpecialist
&& row.sellerRequestType === sellerRequestTypeValues.projectManagerSupport;

  return isSellerTypePMforBSRole;
};

export const emptyOrderNumberCondition = (
  row: IActiveNumbersItem,
) => !row.orderNumber && row.sellerRequestType === sellerRequestTypeValues.contractSupport;
