import React from 'react';
import OrderModalItem from 'shared/OrderModalItem';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import './EngagementAcceptedItems.scss';

interface IEngagementAcceptedItemsProps {
  selectedActiveNumbers: IActiveNumbersItem[];
}

const EngagementAcceptedItems: React.FC<IEngagementAcceptedItemsProps> = ({
  selectedActiveNumbers,
}) => (
  <div className="engagement-accepted-items">
    {selectedActiveNumbers.map(({ location, product, id }) => (
      <OrderModalItem key={id} location={location as string} product={product} />
    ))}
  </div>
);

export default EngagementAcceptedItems;
