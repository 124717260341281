import { TRow } from 'shared/Table/Table.models';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
// eslint-disable-next-line no-unused-vars
import { IOpenapiSchemasParsedItem } from 'core/models/openapi.models';
import { getConstantsFromEnumDescription } from 'core/utilities/openapiSchema';

type CustomColumnDef<T extends TRow> = Omit<ColumnDef<T>, 'header'>;

interface IMetaItem {
  label: string;
  // eslint-disable-next-line no-restricted-globals
  name: string;
}

interface IGetColumnsFromMetaAndShchemaParams {
  metaColumns: IMetaItem[];
  columns: CustomColumnDef<TRow>[];
  retrieveSchema: IOpenapiSchemasParsedItem[];
}

export const getColumnsFromMetaAndShchema = ({
  columns, metaColumns, retrieveSchema,
}:IGetColumnsFromMetaAndShchemaParams) => {
  if (!metaColumns?.length || !retrieveSchema?.length) {
    return [];
  }

  const transformedColumnsLsit = Object.fromEntries(
    columns.map((item) => [item.accessorKey, item]),
  );

  const transformedRetrieveSchemaLsit = Object.fromEntries(
    retrieveSchema.map((schemaField) => {
      const columnItem = transformedColumnsLsit[schemaField.fieldName];
      const additionalParams: {Cell?: ColumnDef<TRow>['Cell']} = {};

      if (schemaField?.enum?.length && schemaField?.description && !columnItem?.Cell) {
        const options = getConstantsFromEnumDescription(schemaField?.description);
        additionalParams.Cell = ({ renderedCellValue }) => (
          options[renderedCellValue as string] || renderedCellValue
        );
      }

      return [schemaField.fieldName, additionalParams];
    }),
  );

  return metaColumns.map(({ label, name }) => {
    const column = transformedColumnsLsit[name];
    const additionalParams = transformedRetrieveSchemaLsit[name];

    return {
      ...column,
      ...additionalParams,
      header: label,
      accessorKey: name,
      minSize: 100,
      enableColumnFilter: false,
      sortDescFirst: false,
    } as ColumnDef<TRow>;
  });
};
