import React, { useMemo } from 'react';
import Loader from 'shared/Loader';
import { Box, Grid } from '@mui/material';
import { useAppSelector } from 'core/store';
import { parseOpenapiSchemaByEndpoint } from 'core/utilities/openapiSchema';
import FormikSingleChackboxField from 'shared/FormikField/FormikSingleChackboxField';
import FormikFieldsFromSchema from 'pages/WirelineEngagements/shared/FormikFieldsFromSchema';
import useInsideSalesListPermissions from 'pages/InsideSales/InsideSalesList/useInsideSalesListPermissions';
import InsideSalesEditAttachments from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm/InsideSalesEditAttachments';

interface IEditRowSidebarFieldsProps {
  isDisabled?: boolean;
  isViewMode?: boolean;
  isLoadingItem: boolean;
  allowedFields: string[];
  showStatusField: boolean;
  editRowId: string | number | undefined | null;
}

const endpointToGetSchemaName = '/api/crm/inside-sales-request/{id}/';

const EditRowSidebarFields: React.FC<IEditRowSidebarFieldsProps> = ({
  editRowId,
  isDisabled,
  isViewMode,
  allowedFields,
  isLoadingItem,
  showStatusField,
}) => {
  const { isAbleToMarkRequestAsCompleted, isFieldViewEnabled } = useInsideSalesListPermissions();

  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const openApiPaths = useAppSelector((state) => state.openapiSchemas.paths);
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const retrieveSchema = useMemo(() => {
    const parsedSchema = (
      parseOpenapiSchemaByEndpoint(
        openApiSchemas,
        openApiPaths,
        endpointToGetSchemaName,
      )
    );

    return parsedSchema.filter((item) => allowedFields.includes(item.fieldName));
  }, [openApiPaths, openApiSchemas, allowedFields]);

  if (!editRowId) {
    return null;
  }

  if (isLoadingSchemas) {
    return (
      <Loader className="inside-sales-edit-row-sidebar__loader" />
    );
  }

  return (
    <>
      {isFieldViewEnabled && (
      <Box className="inside-sales-edit-row-sidebar__fields">
        {isLoadingItem && <Loader />}
        <Grid
          container
          item
          rowSpacing={3}
        >
          <FormikFieldsFromSchema
            retrieveSchema={retrieveSchema}
            isDisabled={isDisabled as boolean}
          />
        </Grid>
      </Box>
      )}
      {
        isAbleToMarkRequestAsCompleted && showStatusField && (
          <Box className="inside-sales-edit-row-sidebar__complete-button-wrapper">
            <FormikSingleChackboxField
              label="Status"
              fieldName="engagementType"
              additionalLabel="Accepted"
              disabled={isDisabled as boolean}
            />
          </Box>
        )
      }
      <InsideSalesEditAttachments
        editRowId={editRowId}
        isViewMode={isViewMode as boolean}
      />
    </>
  );
};

EditRowSidebarFields.defaultProps = {
  isDisabled: false,
  isViewMode: false,
};

export default EditRowSidebarFields;
