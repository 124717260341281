import React from 'react';
import { Typography } from '@mui/material';

import CustomRangePicker from 'shared/CustomRangePicker';
import PeriodAutocomplete from 'shared/PeriodAutocomplete';

import { TPeriod, TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { periodOptions } from 'pages/Dashboard/shared/FiltersByPeriod/constants';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';

import './FiltersByPeriod.scss';

interface IFiltersByPeriod {
  period?: TPeriod;
  onChange: TSetPeriod;
}

const FiltersByPeriod: React.FC<IFiltersByPeriod> = ({ period, onChange }) => (
  <div className="period-filters">
    <Typography variant="body1" className="period-filters__title">
      Period
    </Typography>
    <PeriodAutocomplete
      onChange={onChange}
      options={periodOptions}
    />
    <Typography variant="body1">
      or
    </Typography>
    <CustomRangePicker
      onChange={onChange}
      defaultPeriod={period as TPeriod}
    />
  </div>
);

FiltersByPeriod.defaultProps = {
  period: calculatePeriod(periodMapping, PERIOD_SELECT.CURRENT_MONTH),
};

export default FiltersByPeriod;
