import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';
import { useIsUserType } from 'core/hooks/useIsUserType';

const {
  CONTRACT_SPECIALIST, BILLING_SPECIALIST, PROJECT_MANAGER, RETENTION_MANAGER,
} = UserTeamRoleTeamDepartment;

export const useUserPermissions = () => {
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);

  const isActionsUnavailable = isRetentionManager || isBillingSpecialist || isProjectManager;
  const isRequiredATTRoles = isBillingSpecialist || isProjectManager || isContractSpecialist;

  const isAdmin = useIsUserType(UserType.ADMIN);

  return {
    isAdmin,
    isProjectManager,
    isRequiredATTRoles,
    isRetentionManager,
    isBillingSpecialist,
    isActionsUnavailable,
    isContractSpecialist,
  };
};
