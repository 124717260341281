import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppDispatch } from 'core/store';
import { HTTPService } from 'core/services';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import { TPeriod, TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { notifierMessage } from '@dashboardWirelineInsideSalesDepartment/constants';
import { getRequestStatusAction } from '@dashboardWirelineInsideSalesDepartment/GetRequestStatus';
import { getInsideSalesUsersAction } from '@dashboardWirelineInsideSalesDepartment/GetInsideSalesUsers';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';

import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useUserPermission } from '@dashboardWirelineInsideSalesDepartment/useUserPermission';

interface IInsideSalesDepartmentContext {
  getDashboardData: TSetPeriod;
  period: TPeriod;
  isByUsersComponentUnavailable: boolean;
}

interface IInsideSalesDepartmentProviderProps {
  children: React.ReactNode;
}

const InsideSalesDepartmentContext = createContext<
  IInsideSalesDepartmentContext
>({} as IInsideSalesDepartmentContext);

let requestStatusController = HTTPService.getController();
let insideSalesUsersController = HTTPService.getController();

const InsideSalesDepartmentProvider: React.FC<IInsideSalesDepartmentProviderProps> = ({
  children,
}) => {
  const defaultPeriod = calculatePeriod(periodMapping, PERIOD_SELECT.CURRENT_MONTH);
  const [period, setPeriod] = useState(defaultPeriod);
  const { isByUsersComponentUnavailable } = useUserPermission();

  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const cancelGetRequestStatusRequest = () => {
    HTTPService.cancelRequest(requestStatusController);
  };

  const canselGetInsideSalesUsersRequest = () => {
    HTTPService.cancelRequest(insideSalesUsersController);
  };

  const getRequestStatus = async (newPeriod: TPeriod) => {
    cancelGetRequestStatusRequest();
    requestStatusController = HTTPService.getController();
    try {
      await dispatch(getRequestStatusAction({
        controller: requestStatusController,
        params: newPeriod,
      })).unwrap();
    } catch (e) {
      showErrorNotifier(notifierMessage.requestStatus.error, e);
    }
  };

  const getInsideSalesUsers = async (newPeriod: TPeriod) => {
    if (isByUsersComponentUnavailable) return;
    canselGetInsideSalesUsersRequest();
    insideSalesUsersController = HTTPService.getController();
    try {
      await dispatch(getInsideSalesUsersAction({
        controller: insideSalesUsersController,
        params: newPeriod,
      })).unwrap();
    } catch (e) {
      showErrorNotifier(notifierMessage.insideSalesUsers.error, e);
    }
  };

  const getDashboardData = (newPeriod: TPeriod) => {
    getRequestStatus(newPeriod);
    getInsideSalesUsers(newPeriod);
    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelGetRequestStatusRequest();
    canselGetInsideSalesUsersRequest();
  };

  useEffect(() => {
    getDashboardData(period);
    return () => cancelRequests();
  }, [period]);

  const value = useMemo(() => ({
    getDashboardData,
    period,
    isByUsersComponentUnavailable,
  }), [period, isByUsersComponentUnavailable]);

  return (
    <InsideSalesDepartmentContext.Provider value={value}>
      {children}
    </InsideSalesDepartmentContext.Provider>
  );
};

export const useInsideSalesDepartmentContext = () => useContext(InsideSalesDepartmentContext);

export default InsideSalesDepartmentProvider;
