export const firstPage = 0;
export const defaultPageSize = 15;
export const maxPageSize = 50;
export const pageFieldName = 'page';
export const pageSizeFieldName = 'pageSize';
export const fieldFieldName = 'field';
export const sortFieldName = 'sort';
export const searchFieldName = 'search';
export const filtersList = 'filters';
export const orderList = 'order';

export const rowsPerPageOptions = [5, 10, 15, 20, 25, 30, 50];

export const rowSelectName = 'mrt-row-select';

export const rowActionsName = 'mrt-row-actions';

export const colDefOptionSelect = {
  [rowSelectName]: {
    header: '',
  },
};

export const colDefOptionActionsSingleIcon = {
  [rowActionsName]: {
    header: '',
    size: 60,
  },
};

export const colDefOptionActionsTwoIcons = {
  [rowActionsName]: {
    header: '',
    size: 120,
  },
};

export const defaultColumnPinning = { left: [rowSelectName, rowActionsName] };

export const tableIds = {
  userManagement: 'user-management-table-id',
  customers: 'customers-table-id',
  escalationSupport: 'escalation-support-table-id',
  bookingSellerInfo: 'booking-info-seller-table-id',
  bookingContractSpecialistInfo: 'booking-info-contract-specialist-table-id',
  wirelineEngagements: 'wireline-engagements-table-id',
  activeNumbers: 'active-numbers-table-id',
  renewalSow: 'renewal-sow-table-id',
  salesSow: 'sales-sow-table-id',
  insideSales: 'inside-sales-table-id',
  insideSalesBooking: 'inside-sales-booking-table-id',
  dashboardRetentionManager: 'dashboard-retention-manager-table-id',
};
