import React, { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
import LocationAutocomplete from 'shared/LocationAutocomplete';
import { useTableContext } from 'shared/Table/TableContext';

interface IActiveNumberTableLocationFilterProps {
  setLocationId: Dispatch<SetStateAction<number | null>>
  locationId: number | null;
}

const ActiveNumberTableLocationFilter: React.FC<
IActiveNumberTableLocationFilterProps
> = ({ setLocationId, locationId }) => {
  const { customerId } = useParams();
  const { getTableData } = useTableContext();

  useUpdateEffect(() => {
    getTableData();
  }, [locationId]);

  return (
    <LocationAutocomplete
      onChangeLocation={setLocationId}
      customerId={customerId}
    />
  );
};

export default ActiveNumberTableLocationFilter;
