export const debounceTime = 1000;

export const unitUSD = '$';

export const dateFormatYYYYMM = 'YYYY-MM';
export const dateFormatMMMYYYY = 'MMM, YYYY';

export const dateFormat = 'MM/DD/YYYY';

export const requestFormat = 'YYYY-MM-DD';

export const dateFormatWithTime = `${dateFormat} hh:mm a`;

export const dateFormatWithSeconds = `${dateFormat} hh:mm:ss a`;

export const maxAttachmentFileSize = 10000000;
export const maxAttachmentFilesAmount = 10;
