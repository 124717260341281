import styles from 'assets/styles/_resources.scss';
import { EngagementTypeForChart } from '@dashboardEscalationSupport/EscalationSupportCharts/models';

export const escalationTypeChartFields = {
  [EngagementTypeForChart.NEW]: 'New',
  [EngagementTypeForChart.IN_PROGRESS]: 'In Progress',
  [EngagementTypeForChart.CLOSED]: 'Closed',
};

export const colorsOfEscalationSupportCharts = [
  styles.primaryColor,
  styles.blueColor,
  styles.secondaryColor,
];

export const notifierMessage = {
  escalationSupportTotal: {
    error: 'Failed to complete your request for Request Status, please try again later.',
  },
  escalationSupportType: {
    error: 'Failed to complete your request for Escalation Type, please try again later.',
  },
};

export const escalationTypeDateKeyMobile = {
  'Contract Specialist': 'Contract',
  'Project Manager': 'PM',
  'Billing Specialist': 'Billing / Disco',
  Seller: 'Seller',

};
