import React, { useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { HTTPService } from 'core/services';
import {
  getInsideSalesListItemAction,
  patchInsideSalesListItemAction,
} from 'core/actions/crm/InsideSales/InsideSalesListItem';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import EditRowSidebar from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm/EditRowSidebar';
import {
  loadInsideSalesItemMessages,
  updateInsideSalesItemMessages,
} from 'pages/InsideSales/InsideSalesList/constants';
import {
  EngagementType,
  IInsideSalesListItem,
} from 'pages/InsideSales/model';
import useInsideSalesListPermissions from 'pages/InsideSales/InsideSalesList/useInsideSalesListPermissions';
import { engagementTypesToShowStatusField } from 'pages/InsideSales/constants';

import './InsideSalesEditRowSidebarForm.scss';

let getInsideSalesItemController = HTTPService.getController();
const setNewController = () => { getInsideSalesItemController = HTTPService.getController(); };
const cancelRequest = () => HTTPService.cancelRequest(getInsideSalesItemController);

const EditRowSidebarForm = () => {
  const {
    result: insideSalesItem, isLoading,
  } = useAppSelector((state) => state.insideSales.item);

  const InsideSalesItemKeys = Object.keys(insideSalesItem);

  const dispatch = useAppDispatch();

  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();
  const { getTableData } = useTableContext();
  const {
    activeRow, activeRowMode, activeRowId, cleanActiveRow,
  } = useTableActiveRowContext<IInsideSalesListItem>();

  const showStatusField = !!activeRow?.engagementType
  && engagementTypesToShowStatusField.includes(activeRow?.engagementType);

  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const formikInitialData = useMemo(
    () => ({ ...insideSalesItem, engagementType: isViewMode }),
    [insideSalesItem, isViewMode],
  );

  const { isFieldViewEnabled } = useInsideSalesListPermissions();

  const onSubmit = (params, { resetForm, setErrors }) => {
    if (!activeRowId || !isEditMode) return;

    const { engagementType, ...restParams } = params;
    const requestParams = {
      ...restParams,
      ...(engagementType ? { engagementType: EngagementType.COMPLETED } : {}),
    };

    dispatch(patchInsideSalesListItemAction({ id: activeRowId, params: requestParams }))
      .unwrap()
      .then(() => {
        cleanActiveRow();
        getTableData();
        resetForm();
        showSuccessNotifier(updateInsideSalesItemMessages.success);
      })
      .catch((e) => {
        setErrors(e?.response?.data);
        showErrorNotifier(updateInsideSalesItemMessages.error, e);
      });
  };

  useEffect(() => {
    if (!activeRowId || !(isViewMode || isEditMode) || !isFieldViewEnabled) {
      cancelRequest();
      return;
    }

    setNewController();

    dispatch(getInsideSalesListItemAction({
      id: activeRowId,
      controller: getInsideSalesItemController,
    }))
      .unwrap()
      .catch((e) => showErrorNotifier(loadInsideSalesItemMessages.error, e));

    return cancelRequest;
  }, [activeRowId]);

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={formikInitialData}
    >
      <Form>
        <EditRowSidebar
          isViewMode={isViewMode}
          isLoadingItem={isLoading}
          cleanEditRow={cleanActiveRow}
          showStatusField={showStatusField}
          allowedFields={InsideSalesItemKeys}
          editRowId={(isEditMode || isViewMode) ? activeRowId : undefined}
        />
      </Form>
    </Formik>
  );
};

export default EditRowSidebarForm;
