import React, { useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { HTTPService } from 'core/services';
import TabsHeader from 'shared/Tabs/TabsHeader';
import CustomSidebar from 'shared/CustomSidebar';
import { useAppDispatch, useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { TableRowMode } from 'shared/Table/Table.models';
import { clearCommentsAction, getSalesOrderCommentsListAction } from 'core/actions/Sales/OrderComments';
import CommentsRowContent from 'pages/WirelineEngagements/shared/CommentsRowSidebar/CommentsRowContent';
import { useUserPermission } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/useUserPermission';
import { COMMENTS_ORDER_CATEGORY, commentsTabsConfigs } from 'pages/WirelineEngagements/shared/CommentsRowSidebar/constants';
import './CommentsRowSidebar.scss';

let controller = HTTPService.getController();
const setNewController = () => {
  controller = HTTPService.getController();
};
const cancelRequest = () => HTTPService.cancelRequest(controller);

interface ICommentsRowSidebarProps {
  activeRowId?: string | number | null;
  cleanActiveRow: () => void;
  isCommentsInsideEditOpen: boolean;
  setSecondarySidebarMode: (mode: TableRowMode | null) => void;
  title: string;
}

const CommentsRowSidebar: React.FC<ICommentsRowSidebarProps> = ({
  activeRowId, cleanActiveRow, title, isCommentsInsideEditOpen, setSecondarySidebarMode,
}) => {
  const dispatch = useAppDispatch();
  const {
    results, isLoading, error,
  } = useAppSelector((state) => state.wirelineEngagements.orderComments);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { isAvailableForBillingCategory, isAvailableForGeneralCategory } = useUserPermission();

  const userAbbilityLeaveCommentsByCategory = {
    [COMMENTS_ORDER_CATEGORY.GENERAL]: isAvailableForGeneralCategory,
    [COMMENTS_ORDER_CATEGORY.BILLING]: isAvailableForBillingCategory,
  };

  const commentsByCategory = useMemo(
    () => results.reduce((result, item) => (
      {
        ...result,
        [item.category]: result[item.category] ? [...result[item.category], item] : [item],
      }
    ),
    {}),
    [results],
  );

  const activeTabCategory = commentsTabsConfigs[activeTabIndex]?.category;
  const categoryComments = commentsByCategory[activeTabCategory] || [];
  const canLeaveComments = userAbbilityLeaveCommentsByCategory[activeTabCategory];

  const onCloseCallback = () => {
    if (isCommentsInsideEditOpen) {
      setSecondarySidebarMode(null);
    } else {
      cleanActiveRow();
    }
  };

  const closeEditRowSidebar = () => {
    setActiveTabIndex(0);
    dispatch(clearCommentsAction());
    onCloseCallback();
  };

  const onChangeActiveTab = (_, newCategory) => {
    setActiveTabIndex(newCategory);
  };

  useEffect(() => {
    if (!activeRowId) {
      cancelRequest();
      return;
    }

    setNewController();

    dispatch(getSalesOrderCommentsListAction({
      parentId: activeRowId, controller,
    }));

    return cancelRequest;
  }, [activeRowId]);

  return (
    <CustomSidebar
      className="comments-sidebar"
      isOpen={!!activeRowId}
      onCloseSideBar={closeEditRowSidebar}
      title={title}
      isLoading={isLoading}
      isConfirmDialog={false}
      areButtonsVisible={false}
    >
      <Box
        key={activeRowId}
        className="comments-sidebar__tabs"
      >
        <TabsHeader
          value={activeTabIndex}
          onChange={onChangeActiveTab}
          tabItemsConfig={commentsTabsConfigs}
        />
        <CommentsRowContent
          key={activeTabCategory}
          addCommentError={error}
          activeRowId={activeRowId}
          comments={categoryComments}
          category={activeTabCategory}
          canLeaveComments={canLeaveComments}
          closeEditRowSidebar={closeEditRowSidebar}
        />
      </Box>
    </CustomSidebar>
  );
};

CommentsRowSidebar.defaultProps = {
  activeRowId: null,
};

export default CommentsRowSidebar;
