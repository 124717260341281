import React from 'react';
import Table from 'shared/Table';
import { Typography } from '@mui/material';
import { useAppSelector } from 'core/store';
import { TRow } from 'shared/Table/Table.models';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
import { useTableContext } from 'shared/Table/TableContext';
import { BookingActionButton, BookingReportFilter } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar';

interface IBookingTableProps {
  reportPeriod: string | null
  isLoadingTableFiltersData: boolean
  setReportPeriod: (reportPeriod: string | null) => void;
}

const BookingTable: React.FC<IBookingTableProps> = ({
  reportPeriod, setReportPeriod, isLoadingTableFiltersData,
}) => {
  const { getTableData } = useTableContext();

  const isLoadingTableData = useAppSelector((state) => state.dashboard.bookingTableData.isLoading);
  const bookingDataCount = useAppSelector((state) => state.dashboard.bookingTableData.count);
  const bookingData = useAppSelector((state) => state.dashboard.bookingTableData.results);

  const isLoading = isLoadingTableData || isLoadingTableFiltersData;

  useUpdateEffect(() => {
    getTableData();
  }, [reportPeriod]);

  return (
    <Table
      showToolbar
      enableSelectAll
      showColumnFilters
      enableStickyHeader
      isLoading={isLoading}
      showGlobalFilter={false}
      rowCount={bookingDataCount}
      actionsButton={<BookingActionButton />}
      rows={bookingData as unknown as TRow[]}
      className="seller-department__boking-info__table"
      muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
      toolBarAction={(
        <BookingReportFilter
          reportPeriod={reportPeriod}
          setReportPeriod={setReportPeriod}
        />
      )}
      toolBarStartContent={(
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold' }}
        >
          Booking Table
        </Typography>
      )}
    />
  );
};

export default BookingTable;
