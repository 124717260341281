import React from 'react';
import cx from 'classnames';
import { IComment } from 'shared/widgets/Comments/Comments.models';
import CommentsListView from './CommentsListView';

interface ICommentsListProps {
    comments: IComment[]
    editCommentId: string | number
    editCommentText: string
    onFocusEditComment: (e: React.SyntheticEvent, comment: IComment) => void
    onEditComment: (e: React.SyntheticEvent) => void
    onRemoveComment: (e: React.SyntheticEvent, commentId: number) => void
    className?: string
}

const CommentsList: React.FC<ICommentsListProps> = ({
  comments,
  editCommentId,
  editCommentText,
  onFocusEditComment,
  onEditComment,
  onRemoveComment,
  className,
}) => (
  <div className={cx('widget-comments__list', className)}>
    <CommentsListView
      comments={comments}
      editCommentId={editCommentId}
      editCommentText={editCommentText}
      onFocusEditComment={onFocusEditComment}
      onEditComment={onEditComment}
      onRemoveComment={onRemoveComment}
    />
  </div>
);

CommentsList.defaultProps = {
  className: '',
};

export default CommentsList;
