export enum EngagementTypeForChart {
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW= 'NEW'
}

export interface IEscalationSupportTypeItem {
  // eslint-disable-next-line no-restricted-globals
  name: string,
  value: {
    [EngagementTypeForChart.CLOSED]?: number,
    [EngagementTypeForChart.IN_PROGRESS]?: number,
    [EngagementTypeForChart.NEW]?: number
  },
}
