import { createAsyncThunk } from '@reduxjs/toolkit';
import { postBookingInfoBookedOrder } from 'core/api/sales.api';
// eslint-disable-next-line no-unused-vars
import { IBookedOrderParams } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/bookedOrder.model';

const bookingInfoBookedOrderAction = createAsyncThunk(
  'bookingInfoBookedOrderAction',
  async (data: IBookedOrderParams, { rejectWithValue }) => {
    try {
      await postBookingInfoBookedOrder(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export default bookingInfoBookedOrderAction;
