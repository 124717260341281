import React, { useState } from 'react';
import { useAppDispatch } from 'core/store';
import CommentsList from 'shared/widgets/Comments/CommentsList';
import { IComment } from 'shared/widgets/Comments/Comments.models';
import CommentsTextArea from 'pages/WirelineEngagements/shared/CommentsRowSidebar/CommentsTextArea';
import {
  clearCommentsErrorsAction,
  createSalesOrderCommentAction,
  editSalesOrderCommentItemAction,
  deleteSalesOrderCommentItemAction,
} from 'core/actions/Sales/OrderComments';

interface ICommentsRowContentProps {
  category: string
  comments: IComment[]
  addCommentError: string
  canLeaveComments: boolean
  closeEditRowSidebar: () => void
  activeRowId?: string | number | null | undefined
}

const CommentsRowContent: React.FC<ICommentsRowContentProps> = ({
  comments, category, activeRowId, addCommentError, closeEditRowSidebar, canLeaveComments,
}) => {
  const dispatch = useAppDispatch();

  const [newCommentText, setNewCommentText] = useState('');
  const [editCommentId, setEditCommentId] = useState('');
  const [editCommentText, setEditCommentText] = useState('');

  const onFocusEditComment = (e, comment) => {
    e.preventDefault();
    const { id } = comment;
    setEditCommentId(id);
    setEditCommentText(e.target.value);
    dispatch(clearCommentsErrorsAction());
  };

  const onEditComment = (e) => {
    setEditCommentText(e.target.value);
  };

  const onRemoveComment = (e, commentId) => {
    e.preventDefault();
    dispatch(deleteSalesOrderCommentItemAction({
      parentId: activeRowId as string | number,
      id: commentId,
    }));
  };

  const onChangeComment = (e) => {
    setNewCommentText(e.target.value);
    dispatch(clearCommentsErrorsAction());
  };

  const onCreateNewComment = (e) => {
    e.preventDefault();
    dispatch(createSalesOrderCommentAction({
      parentId: activeRowId as string | number,
      params: { text: newCommentText, category },
    }));
    setNewCommentText('');
  };

  const onUpdateComment = async (e) => {
    e.preventDefault();

    await dispatch(editSalesOrderCommentItemAction({
      parentId: activeRowId as string | number,
      id: Number(editCommentId),
      params: { text: editCommentText },
    })).unwrap();

    setEditCommentId('');
    setEditCommentText('');
  };

  return (
    <div className="comments-sidebar__content">
      <CommentsList
        comments={comments}
        editCommentId={editCommentId}
        editCommentText={editCommentText}
        onFocusEditComment={onFocusEditComment}
        onEditComment={onEditComment}
        onRemoveComment={onRemoveComment}
        className="comments-sidebar__comments"
      />
      <CommentsTextArea
        error={addCommentError}
        editCommentId={editCommentId}
        newCommentText={newCommentText}
        onCancel={closeEditRowSidebar}
        onChangeComment={onChangeComment}
        onUpdateComment={onUpdateComment}
        canLeaveComments={canLeaveComments}
        onCreateNewComment={onCreateNewComment}
      />
    </div>
  );
};

CommentsRowContent.defaultProps = {
  activeRowId: null,
};

export default CommentsRowContent;
