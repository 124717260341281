import React, { useState } from 'react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import Table from 'shared/Table';
import { useTableContext } from 'shared/Table/TableContext';
import { useAppSelector } from 'core/store';
import CreateButton from 'shared/CreateButton';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import SowDataActionsButton from '@EngagementsSowData/SowDataActionsButton';
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { defaultColumnPinning, muiTableContainerProps } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import WirelineEngagementsSowDataTableRowActions from '@EngagementsSowData/WirelineEngagementsSowDataTableRowActions';
import WirelineEngagementsSowDataModals from '@EngagementsSowData/WirelineEngagementsSowDataModals';
import { colDefOptionActionsTwoIcons, colDefOptionSelect } from 'shared/Table/constants';
import { useUserPermissions } from '@EngagementsSowData/useUserPermissions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useParams } from 'react-router-dom';
import ExportTableXlsxFile from 'shared/ExportTableXlsxFile';
import { exportSowData } from 'core/api/sales.api';

const WirelineEngagementsSowDataTable = () => {
  const { rowSelection, tableFilters, setRowSelection } = useTableContext();
  const { customerId } = useParams();
  const { pagination: { pageSize, pageIndex } } = tableFilters;

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext<ISowDataListItem>();
  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const {
    isProjectManager,
    isRetentionManager,
    isBillingSpecialist,
    isActionsUnavailable,
    isContractSpecialist,
  } = useUserPermissions();

  const results = useAppSelector((state) => state.wirelineEngagements.salesSow.results);
  const count = useAppSelector((state) => state.wirelineEngagements.salesSow.count);
  const isLoading = useAppSelector((state) => state.wirelineEngagements.salesSow.isLoading);

  const isAddProductButtonDisabled = (
    isBillingSpecialist || isContractSpecialist || isRetentionManager || isProjectManager
    || isEditMode || isViewMode || !isEmpty(rowSelection)
  );

  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);

  const selectedOrders = useTableSelectedRows<ISowDataListItem>({ rows: results });

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [orderAction, setOrderAction] = useState(OrderActions.REQUEST_QUOTES);

  const exportFileName = results?.[0]?.customerCompanyName ? `${results?.[0]?.customerCompanyName}_SOW.xlsx` : 'SOW.xlsx';

  const openAddProductModal = () => setIsAddProductModalOpen(true);

  const addProductActionComponent = secondarySidebarMode ? <MoreHorizIcon /> : 'add order';

  const addProductAction = (
    <CreateButton
      className="sow-data-btn"
      onClick={openAddProductModal}
      text={addProductActionComponent}
      disabled={isAddProductButtonDisabled}
    />
  );

  const exportParams = {
    id: customerId!,
    additionalIds: Object.keys(rowSelection).join(','),
    page: pageIndex + 1,
    pageSize,
  };

  const renderBottomToolbarCustomActions = () => (
    <ExportTableXlsxFile
      params={exportParams}
      setRowSelection={setRowSelection}
      exportAction={exportSowData}
      fileName={exportFileName}
    />
  );

  return (
    <>
      <Table
        className="wireline-table"
        isLoading={isLoading}
        rowCount={count}
        toolBarAction={addProductAction}
        showColumnFilters
        showGlobalFilter
        rows={results}
        handleRowClick={noop}
        enableSelectAll={false}
        initialColumnPinning={defaultColumnPinning}
        muiTableContainerProps={muiTableContainerProps}
        enableStickyHeader
        actionsButton={(
          <SowDataActionsButton
            selectedOrders={selectedOrders}
            setIsActionsModalOpen={setIsActionsModalOpen}
            setOrderAction={setOrderAction}
          />
        )}
        renderRowActions={({ row: { original } }) => (
          <WirelineEngagementsSowDataTableRowActions
            row={original as unknown as ISowDataListItem}
          />
        )}
        displayColumnDefOptions={{
          ...colDefOptionSelect,
          ...(!isActionsUnavailable ? colDefOptionActionsTwoIcons : undefined),
        }}
        renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
      />
      <WirelineEngagementsSowDataModals
        isAddProductModalOpen={isAddProductModalOpen}
        setIsAddProductModalOpen={setIsAddProductModalOpen}
        isActionsModalOpen={isActionsModalOpen}
        setIsActionsModalOpen={setIsActionsModalOpen}
        orderAction={orderAction}
        selectedOrders={selectedOrders}
      />
    </>
  );
};

export default WirelineEngagementsSowDataTable;
