import { attLabel } from 'core/labels';
import { ORDER_TYPE, PmEngagementType } from '@EngagementsSowData/sowData.model';

interface IRowData {
  tsd: string
  pmEngagementType: string | null
  contractOrderSupport: string | null
}

export const checkIfOrderNonAttTSD = (
  rowData: IRowData,
) => rowData.tsd !== attLabel;

export const isOrderNonAttForRoles = (row: IRowData, roles: boolean[]) => roles
  .some((item) => item)
 && checkIfOrderNonAttTSD(row);

export const isActiveNumbersItemCanceled = (
  row: IRowData,
) => row?.contractOrderSupport === ORDER_TYPE.CANCELED
|| row?.pmEngagementType === PmEngagementType.CANCELED;
