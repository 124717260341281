import { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-unused-vars
import { TRow } from 'shared/Table/Table.models';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
// eslint-disable-next-line no-unused-vars
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { clearTSDsAction, getTSDsAction } from 'core/actions/Sales/GetTSDs';
import { convertTSDsDataToOptions } from '@dashboardWireline/WirelineDepartments/utilites';

export const useBookingTableColumns = (columns: ColumnDef<TRow>[]) => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();

  const TSDsList = useAppSelector((state) => state.wirelineEngagements.TSDs.data);
  const isLoadingTSDsList = useAppSelector((state) => state.wirelineEngagements.TSDs.isLoading);

  const getTSDs = () => {
    try {
      dispatch(getTSDsAction())
        .unwrap();
    } catch (e) {
      showErrorNotifier('Failed to load TSD', e);
    }
  };

  const bookingColumns = useMemo(() => {
    if (!TSDsList.length) return columns;

    const tsdFilterOptions = convertTSDsDataToOptions(TSDsList);
    return columns.map((column) => (column.accessorKey === 'tsd'
      ? { ...column, filterSelectOptions: tsdFilterOptions }
      : column));
  }, [TSDsList]);

  useEffect(() => {
    getTSDs();

    return () => {
      dispatch(clearTSDsAction());
    };
  }, []);

  return { bookingColumns, isLoading: isLoadingTSDsList };
};
