import React from 'react';
import { TableRowMode } from 'shared/Table/Table.models';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import CommentsRowSidebar from 'pages/WirelineEngagements/shared/CommentsRowSidebar';
import EditRowSidebarForm from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals/EditRowSidebarForm';
import CancelOrderModalForm from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrderModalForm';
import EscalationSupportModalForm from 'pages/WirelineEngagements/shared/EscalationSupportModalForm';

const ActiveNumbersTableModals = () => {
  const {
    activeRowId, activeRowMode, activeRow, cleanActiveRow,
    secondarySidebarMode, setSecondarySidebarMode,
  } = useTableActiveRowContext<IActiveNumbersItem>();

  const { isCommentMode, isEscalationSupportMode } = getTableRowModeBoolean(activeRowMode);

  const isCommentsInsideEditOpen = secondarySidebarMode === TableRowMode.COMMENT;

  const isActiveRow = isCommentMode || isCommentsInsideEditOpen;
  const commentsActiveRowId = (isActiveRow ? activeRowId : null) as string | number | null;

  return (
    <>
      <EditRowSidebarForm />
      <EscalationSupportModalForm
        escalationSupportRowId={isEscalationSupportMode ? activeRowId : undefined}
        escalationSupportRow={isEscalationSupportMode ? activeRow : undefined}
        cleanEscalationSupportRow={cleanActiveRow}
      />
      <CancelOrderModalForm
        activeRowMode={activeRowMode}
        activeRow={activeRow}
        cleanActiveRow={cleanActiveRow}
      />
      <CommentsRowSidebar
        title="Order Comments"
        activeRowId={commentsActiveRowId}
        cleanActiveRow={cleanActiveRow}
        isCommentsInsideEditOpen={isCommentsInsideEditOpen}
        setSecondarySidebarMode={setSecondarySidebarMode}
      />
    </>
  );
};

export default ActiveNumbersTableModals;
