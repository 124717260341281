import React from 'react';
import isNil from 'lodash/isNil';
import {
  FormControl, IconButton, InputLabel, MenuItem, Select,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { IOption } from 'shared/FormikField/FormikField.model';
import { SelectMenuProps } from 'shared/CustomSingleSelect/constants';

interface ICustomSingleSelectProps {
  label: string
  value: IOption['value'] | null | undefined,
  options: IOption[]
  isClearable?: boolean
  className?: string
  onChange: (value: string | null) => void
}

const CustomSingleSelect: React.FC<ICustomSingleSelectProps> = ({
  value,
  label,
  options,
  onChange,
  className,
  isClearable,
}) => {
  const renderValue = isNil(value) ? '' : value;

  const handleChange = (event) => onChange(event.target.value);

  const onClear = () => {
    onChange(null);
  };

  const clearButton = value && isClearable ? (
    <IconButton
      size="small"
      onClick={onClear}
      data-testid="clear-button"
      className="single-select__clear"
    >
      <ClearIcon fontSize="small" />
    </IconButton>
  ) : undefined;

  return (
    <FormControl
      fullWidth
      size="small"
      sx={{ m: 1, minWidth: 190 }}
      className={className as string}
      data-testid="custom-single-select-id"
    >
      <InputLabel id="ingle-select-label">{label}</InputLabel>
      <Select
        labelId="single-select-label"
        id="single-select"
        className="single-select"
        label={label}
        variant="outlined"
        value={renderValue}
        onChange={handleChange}
        endAdornment={clearButton}
        MenuProps={SelectMenuProps}
      >
        {options.map(({
          value: optionValue,
          label: optionLabel,
          disabled: optionDisabled,
        }) => (
          <MenuItem
            autoFocus={false}
            key={`${optionValue}`}
            value={`${optionValue}`}
            disabled={optionDisabled ?? false}
          >
            {optionLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomSingleSelect.defaultProps = {
  isClearable: false,
  className: '',
};

export default CustomSingleSelect;
