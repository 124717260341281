import React from 'react';
import { useIsUserType } from 'core/hooks/useIsUserType';
import { UserType } from 'pages/UserManagement/UserManagement.models';
import BookedOrderButton from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/BookingActionButton/BookedOrder/BookedOrderButton';

const BookingActionButton = () => {
  const isAdmin = useIsUserType(UserType.ADMIN);

  if (!isAdmin) return null;

  return <BookedOrderButton />;
};

export default BookingActionButton;
