import React, { Dispatch, SetStateAction } from 'react';
import Table from 'shared/Table';
import { useAppSelector } from 'core/store';
import { colDefOptionActionsSingleIcon, colDefOptionActionsTwoIcons, colDefOptionSelect } from 'shared/Table/constants';
import { useUserPermissions } from '@EngagementsActiveNumbers/useUserPermissions';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import ActiveNumbersEngagementAccepted from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted';
import { defaultColumnPinning, muiTableContainerProps } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import WirelineEngagementsActiveNumbersRowActions
  from '@EngagementsActiveNumbers/WirelineEngagementsActiveNumbersRowActions';
import ActiveNumbersTableModals from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals';
import ActiveNumberTableLocationFilter from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumberTableLocationFilter';

interface IActiveNumbersTableProps {
  setLocationId: Dispatch<SetStateAction<number | null>>
  locationId: number | null;
}

const ActiveNumbersTable: React.FC<
  IActiveNumbersTableProps
> = ({ setLocationId, locationId }) => {
  const isActiveNumbersLoading = useAppSelector((
    state) => state.wirelineEngagements.activeNumbers.isLoading);

  const activeNumbersData = useAppSelector((
    state) => state.wirelineEngagements.activeNumbers.results);

  const activeNumbersCount = useAppSelector((
    state) => state.wirelineEngagements.activeNumbers.count);

  const { isToolbarUnavailable, isEditingAvailable, isProjectManager } = useUserPermissions();

  const toolBarCustomSearch = (
    isProjectManager ? (
      <ActiveNumberTableLocationFilter
        setLocationId={setLocationId}
        locationId={locationId}
      />
    ) : undefined
  );

  return (
    <>
      <Table
        className="wireline-table"
        isLoading={isActiveNumbersLoading}
        rowCount={activeNumbersCount}
        showColumnFilters={false}
        rows={activeNumbersData}
        enableSelectAll={false}
        toolBarCustomSearch={toolBarCustomSearch}
        defaultVisibility={{ id: false, orderType: false }}
        actionsButton={<ActiveNumbersEngagementAccepted />}
        showToolbar={!isToolbarUnavailable}
        showGlobalFilter={!isToolbarUnavailable}
        initialColumnPinning={defaultColumnPinning}
        enableStickyHeader
        muiTableContainerProps={muiTableContainerProps}
        renderRowActions={({ row: { original } }) => (
          <WirelineEngagementsActiveNumbersRowActions
            row={original as unknown as IActiveNumbersItem}
          />
        )}
        displayColumnDefOptions={{
          ...colDefOptionSelect,
          ...(isEditingAvailable ? colDefOptionActionsTwoIcons : colDefOptionActionsSingleIcon),
        }}
      />
      <ActiveNumbersTableModals />
    </>
  );
};

export default ActiveNumbersTable;
