// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import {
// eslint-disable-next-line no-unused-vars
  FormHelperText, MenuItem, Select, FormControl, SelectChangeEvent, InputAdornment,
  SvgIconTypeMap,
} from '@mui/material';
import { useField } from 'formik';
import Loader from 'shared/Loader';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import FormikSelectClearAdornment from 'shared/FormikField/FormikSelectField/FormikSelectClearAdornment';
import FormikFieldWrapper from '../FormikFieldWrapper';
import { IOption, GRID_DIRECTION } from '../FormikField.model';

import '../FormikField.scss';
import './FormikSelectField.scss';

interface IFormikSelectFieldProps {
  label?: string
  fieldName: string
  options: IOption[]
  onChange?: (e: SelectChangeEvent<any>) => void,
  required?: boolean
  fullWidth?: boolean
  disabled?: boolean
  nullable?: boolean
  isLoading?: boolean
  gridDirection?: GRID_DIRECTION
  StartIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | undefined
}

const FormikSelectField: FC<IFormikSelectFieldProps> = ({
  fieldName, label, options, onChange, required, fullWidth, disabled,
  nullable, isLoading, gridDirection, StartIcon,
}) => {
  const [field, error] = useField(fieldName);

  const onChangeHandler = (event: SelectChangeEvent<any>) => {
    onChange?.(event);
    field.onChange(event);
  };

  const isError = Boolean(error.error && error.touched);
  const errorText = isError ? error.error : ' ';

  const startAdornment = StartIcon ? (
    <InputAdornment position="start">
      <StartIcon />
    </InputAdornment>
  ) : null;

  if (isLoading) {
    return (
      <div className="formik-select-field__loader-wrapper">
        <Loader size={36} />
      </div>
    );
  }

  return (
    <FormikFieldWrapper
      fieldName={fieldName}
      label={label}
      required={required as boolean}
      gridDirection={gridDirection as GRID_DIRECTION}
    >
      <FormControl error={isError}>
        <Select
          {...field}
          id={fieldName}
          variant="outlined"
          className="formik-select-field"
          onChange={onChangeHandler}
          fullWidth={fullWidth as boolean}
          required={required as boolean}
          disabled={disabled as boolean}
          startAdornment={startAdornment}
          endAdornment={(
            <FormikSelectClearAdornment
              required={required as boolean}
              fieldName={fieldName}
              disabled={disabled as boolean}
              nullable={nullable as boolean}
            />
          )}
        >
          {options.map(({
            value: optionValue,
            label: optionLabel,
            disabled: optionDisabled,
          }) => (
            <MenuItem
              key={`${optionValue}`}
              value={`${optionValue}`}
              disabled={optionDisabled ?? false}
            >
              {optionLabel}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={isError}>
          {errorText}
        </FormHelperText>
      </FormControl>
    </FormikFieldWrapper>
  );
};

FormikSelectField.defaultProps = {
  label: '',
  onChange: () => {},
  required: false,
  fullWidth: false,
  disabled: false,
  nullable: false,
  isLoading: false,
  StartIcon: undefined,
  gridDirection: GRID_DIRECTION.ROW,
};

export default FormikSelectField;
