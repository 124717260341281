import { createSlice } from '@reduxjs/toolkit';
import { IBookingDataItem } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';

export interface IState {
  count: number
  isLoading: boolean
  error: object | null
  results: IBookingDataItem[]
}

const initialState: IState = {
  count: 0,
  results: [],
  error: null,
  isLoading: true,
};

const BookingSellerDataSlice = createSlice({
  name: 'getBookingSellerData',
  initialState,
  reducers: {
    getBookingSellerDataRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getBookingSellerDataSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    getBookingSellerDataFailure: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload,
      results: [],
    }),
    clearBookingSellerDataAction: () => initialState,
  },
});

export const {
  getBookingSellerDataRequest,
  getBookingSellerDataSuccess,
  getBookingSellerDataFailure,
  clearBookingSellerDataAction,
} = BookingSellerDataSlice.actions;

export const getBookingSellerDataReducer = BookingSellerDataSlice.reducer;
