import { firstPage, maxPageSize } from 'shared/Table/constants';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';

export const createCustomerNotifierMessage = {
  sucsess: 'Customer was created successfully.',
  error: 'Failed to create Customer, please try again later.',
};

export const getDraftNotifierMessage = {
  error: 'Failed to complete your request, please try again later.',
};

export const sendLOANotifierMessage = {
  success: 'LOA was sent successfully.',
  error: 'Failed to send LOA',
};

export const getSellersParams = {
  page: firstPage + 1,
  page_size: maxPageSize,
  excludeTechnicalOverlays: true,
  department: UserTeamRoleTeamDepartment.SELLER,
};

export default { createCustomerNotifierMessage, getDraftNotifierMessage, sendLOANotifierMessage };

export const customerStatus = {
  active: 1,
  inactive: 0,
};
