import React from 'react';
import noop from 'lodash/noop';
import { Box } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import styles from 'assets/styles/_resources.scss';
import TabComponent from 'shared/Tabs/TabComponent';

interface ITabItemsConfig {
  // eslint-disable-next-line no-restricted-globals
  name?: string
  icon?: any
  disabled?: boolean
  dataComponent?: any
}

interface ITabsHeader {
  value: number | string
  tabItemsConfig: ITabItemsConfig[],
  onChange: (e: React.SyntheticEvent, value: string | number) => void
  onClick?: (index: number) => void
  isScrollable?: boolean
}

const TabsHeader: React.FC<ITabsHeader> = ({
  value, tabItemsConfig, onChange, onClick, isScrollable,
}) => (
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <MuiTabs
      value={value}
      onChange={onChange}
      className="tabs"
      data-testid="tabs"
      variant={isScrollable ? 'scrollable' : 'fullWidth'}
      scrollButtons="auto"
      allowScrollButtonsMobile
      sx={{ '.MuiTabs-indicator': { backgroundColor: `${styles.primaryColor}!important` } }}
    >
      {tabItemsConfig.map((item, i) => {
        const selected = value === i;
        return (
          <MuiTab
            key={item.name}
            component={React.forwardRef((props, ref) => (
              <TabComponent
                icon={item.icon}
                title={item.name}
                dataComponent={item.dataComponent}
                onclick={() => onClick?.(i)}
                selected={selected}
                disabled={item.disabled}
                {...ref}
                {...props}
              />
            ))}
          />
        );
      })}
    </MuiTabs>
  </Box>
);

TabsHeader.defaultProps = {
  onClick: noop,
  isScrollable: false,
};

export default TabsHeader;
