export interface IBookingDataItem {
    id: number;
    bookingStatus: string;
    seller: string;
    customerName: string;
    carrier: string | null;
    bookingId: string;
    orderType: string;
    pmEngagementType: string | null;
    mrc: string | null;
    signed: string | null;
    projectEom: string | null;
    bookedMrc: string | null;
    bookedNrc: string | null;
    oppIds: string;
    orderNumber: string;
    // eslint-disable-next-line no-restricted-globals
    location: string;
    product: string;
    bandwidth: string;
    system: string | null;
    quantityOfNewBvoipTnsToReserve: number | null;
    term: number | null;
    pbxInterfaceType: string | null;
    createdDatetime: string;
    dateBooked: string | null;
    contractRequestDate: string;
    contractSentDate: string | null;
    contractCountersigningDate: string | null;
    orderHandoffDate: string | null;
    loaSent: boolean;
    contractSpecialist: string;
    whosLogin: string | null;
    subAgentName: string;
    existingContractId: string;
    contractId: string;
    accountNumber: string;
    circuitId: string;
    notes: string;
  }

export enum BookingStatus {
    TRANSACTIONAL = 'TRANSACTIONAL',
    PENDING_CUSTOMER_SIGNATURE = 'PENDING_CUSTOMER_SIGNATURE',
    PENDING_ORDER_NUMBER = 'PENDING_ORDER_NUMBER',
    WORKING_ENGAGEMENT = 'WORKING_ENGAGEMENT',
    PROJECT_BOOKED = 'PROJECT_BOOKED',
    BOOKED = 'BOOKED',
    CANCELED_ENGAGEMENT = 'CANCELED_ENGAGEMENT',
  }

export enum ServiceProductType {
    COMPLEX = 'COMPLEX',
    TRANSACTIONAL = 'TRANSACTIONAL',
  }
