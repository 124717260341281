import { UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';

import { useIsUserType } from 'core/hooks/useIsUserType';

const { RETENTION_MANAGER } = UserTeamRoleTeamDepartment;

export const useUserPermission = () => {
  const isAdmin = useIsUserType(UserType.ADMIN);

  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);

  const isRenewalSowDataAvailable = isAdmin || isRetentionManager;

  return {
    isRenewalSowDataAvailable,
  };
};
