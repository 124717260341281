import React from 'react';
import get from 'lodash/get';
import {
  Avatar, CircularProgress, Grid, IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { dateFormatWithSeconds } from 'core/constants';
import { IComment } from 'shared/widgets/Comments/Comments.models';
import cx from 'classnames';

interface ICommentItemProps {
    editCommentId: string | number;
    comment: IComment;
    isDisabledTextField: boolean;
    isEditMode: boolean;
    editCommentText: string;
    onFocusEditComment: (e: React.SyntheticEvent, comment: IComment) => void;
    onEditComment: (e: React.SyntheticEvent) => void;
    onRemoveComment: (e: React.SyntheticEvent, commentId: number) => void;
}

const CommentItem: React.FC<ICommentItemProps> = ({
  comment,
  isDisabledTextField,
  isEditMode,
  editCommentText,
  onFocusEditComment,
  onEditComment,
  onRemoveComment,
  editCommentId,
}) => {
  const {
    id, text, creatorFirstName, creatorLastName,
    createdDatetime, isEditable, error, isLoading,
    creatorFullname,
  } = comment;

  const avatarText = creatorFullname
    ? creatorFullname.split(' ').map((name) => name[0]).join('')
    : get(creatorFirstName, '0', '') + get(creatorLastName, '0', '');

  const nameToDisplay = creatorFullname || `${creatorFirstName} ${creatorLastName}`;

  const textFieldValue = isEditMode ? text : editCommentText;
  const isDeleteDisabled = Boolean(editCommentId) || !isEditable;

  const deleteButton = isLoading ? (
    <CircularProgress color="secondary" size={22} />
  ) : (
    <IconButton
      disabled={isDeleteDisabled}
      aria-label="Remove comment"
      onClick={(e) => onRemoveComment(e, id)}
    >
      <DeleteOutlinedIcon />
    </IconButton>
  );

  return (
    <ListItem
      alignItems="flex-start"
      disableGutters
      dense
    >
      <ListItemAvatar>
        <Avatar
          alt={nameToDisplay}
          className="widget-comments__list-avatar"
        >
          {avatarText}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={(
          <Typography>
            {nameToDisplay}
          </Typography>
        )}
        secondary={(
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="time" className="widget-comments__list-time">
                {dayjs(createdDatetime).format(dateFormatWithSeconds)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                value={textFieldValue}
                onClick={(e) => onFocusEditComment(e, comment)}
                onChange={onEditComment}
                multiline
                error={!!error}
                helperText={error}
                className={cx('widget-comments__list-textfield', {
                  disabled: !isEditable,
                  disabledTextField: isDisabledTextField,
                })}
              />
            </Grid>
          </Grid>
        )}
      />
      <ListItemSecondaryAction>
        {deleteButton}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default CommentItem;
