import React from 'react';
import CustomModal from 'shared/CustomModal';
import { useAppDispatch } from 'core/store';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import EngagementAcceptedItems
  from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAcceptedModal/EngagementAcceptedItems';
import { pmEngagementAcceptedAction, billingEngagementAcceptedAction } from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAccepted';
import {
  buttonWidth,
  engagementAcceptedButtonText,
  engagementAcceptedModalTitle, engagementAcceptedNotifierMessage,
} from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/constants';

const { BILLING_SPECIALIST, PROJECT_MANAGER } = UserTeamRoleTeamDepartment;
interface IEngagementAcceptedModalProps {
  isModalOpen: boolean;
  onCloseModal: ()=> void;
  selectedActiveNumbers: IActiveNumbersItem[];
  actionUserRole: string;
}

const EngagementAcceptedModal: React.FC<IEngagementAcceptedModalProps> = (
  {
    isModalOpen, onCloseModal, selectedActiveNumbers, actionUserRole,
  },
) => {
  const { rowSelection, getTableData } = useTableContext();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const selectedRowIds = Object.keys(rowSelection);

  const onPMEngagementAccepted = async () => {
    await Promise.all(selectedRowIds.map(
      async (id) => {
        try {
          await dispatch(pmEngagementAcceptedAction(id))
            .unwrap();
          showSuccessNotifier(engagementAcceptedNotifierMessage.success);
        } catch (error) {
          showErrorNotifier(engagementAcceptedNotifierMessage.error, error);
        }
      }),
    );
  };

  const onBSEngagementAccepted = async () => {
    await Promise.all(selectedRowIds.map(
      async (id) => {
        try {
          await dispatch(billingEngagementAcceptedAction(id))
            .unwrap();
          showSuccessNotifier(engagementAcceptedNotifierMessage.success);
        } catch (error) {
          showErrorNotifier(engagementAcceptedNotifierMessage.error, error);
        }
      }),
    );
  };

  const onEngagementAcceptedActions = {
    [PROJECT_MANAGER]: onPMEngagementAccepted,
    [BILLING_SPECIALIST]: onBSEngagementAccepted,
  };

  const onEngagementAccepted = async () => {
    const onEngagementAcceptedAction = onEngagementAcceptedActions[actionUserRole];
    await onEngagementAcceptedAction?.();

    getTableData();
    onCloseModal();
  };

  return (
    <CustomModal
      areButtonsVisible
      buttonWidth={buttonWidth}
      isModalOpen={isModalOpen}
      onCloseModal={onCloseModal}
      title={engagementAcceptedModalTitle}
      onClickConfirm={onEngagementAccepted}
      confirmButtonText={engagementAcceptedButtonText.confirmButtonText}
      cancelButtonText={engagementAcceptedButtonText.cancelButtonText}
    >
      <EngagementAcceptedItems
        selectedActiveNumbers={selectedActiveNumbers}
      />
    </CustomModal>
  );
};

export default EngagementAcceptedModal;
