import {
  getBookingSellerDataRequest,
  getBookingSellerDataSuccess,
  getBookingSellerDataFailure,
} from './GetBookingSellerData';

import getBookingData from './api.service';

const getBookingSellerDataAction = (
  controller,
  params: {
      page: number;
      page_size: number;
      search: string;
      ordering?: string | undefined;
    }) => async (dispatch) => {
  const { signal } = controller;

  try {
    dispatch(getBookingSellerDataRequest());

    const { data } = await getBookingData(signal, params);
    dispatch(getBookingSellerDataSuccess(data));
  } catch (error) {
    dispatch(getBookingSellerDataFailure(error));
  }
};

export default getBookingSellerDataAction;
