import React from 'react';
import dayjs from 'dayjs';
import { dateFormat } from 'core/constants';
import MoneyCell from 'shared/Table/shared/MoneyCell';
import RenewalStatus from '@EngagementsRenewalSowData/RenewalStatus';
import { RenewalStatusSow } from '@EngagementsRenewalSowData/renewalSowData.models';
import RequestProvidedDate from 'shared/Table/shared/RequestProvidedDate';
import EscalationSupportView from 'pages/WirelineEngagements/shared/EscalationSupportView';
import { boolLabels, providedBodyCellStyle, providedHeadCellStyle } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';

export const renewalSowDataTitle = 'Renewal SOW Data';

export const disabledCheckboxRowKeyRenewalSow = 'renewalEngagementAcceptedDate';

export const columns = [
  {
    header: 'Engagement Accepted Date',
    accessorKey: 'renewalEngagementAcceptedDate',
    minWidth: '100px',
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate requestProvidedDate={renderedCellValue as string} />
    ),
    sortDescFirst: false,
  },
  {
    header: 'Escalation Support',
    accessorKey: 'hasRenewalEscalationRequests',
    Cell: ({ row: { original } }) => (
      <EscalationSupportView
        hasEscalationRequests={original.hasRenewalEscalationRequests as boolean}
        product={original.product as string}
        customerCompanyName={original.customerName as string}
      />
    ),
  },
  {
    header: 'Retention Manager',
    accessorKey: 'retentionManagerFullname',
  },
  {
    header: 'Engagement Type',
    accessorKey: 'engagementType',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RenewalStatus contractStatus={renderedCellValue} />
    ),
  },
  {
    header: 'Customer Name',
    accessorKey: 'customerName',
  },
  {
    header: 'Customer Location',
    accessorKey: 'location',
  },
  {
    header: 'Product',
    accessorKey: 'product',
  },
  {
    header: 'Notes',
    accessorKey: 'renewalNotes',
  },
  {
    header: 'Existing Account Number',
    accessorKey: 'accountNumber',
  },
  {
    header: 'Original Term',
    accessorKey: 'term',
  },
  {
    header: 'Original MRC',
    accessorKey: 'mrc',
    Cell: MoneyCell,
  },
  {
    header: 'Original NRC',
    accessorKey: 'nrc',
    Cell: MoneyCell,
  },
  {
    header: 'ATT Circuit ID',
    accessorKey: 'attCircuitId',
  },
  {
    header: 'Existing Contract ID',
    accessorKey: 'contractId',
  },
  {
    header: 'New Contract ID',
    accessorKey: 'newContractId',
  },
  {
    header: 'New Account Number',
    accessorKey: 'newAccountNumber',
  },
  {
    header: 'New Term',
    accessorKey: 'newTerm',
  },
  {
    header: 'New MRC',
    accessorKey: 'newMrc',
    Cell: MoneyCell,
  },
  {
    header: 'New NRC',
    accessorKey: 'newNrc',
    Cell: MoneyCell,
  },
  {
    header: 'New Contract Countersigning Date',
    accessorKey: 'newContractCountersigningDate',
    Cell: ({ renderedCellValue }) => (
      renderedCellValue && dayjs(renderedCellValue).format(dateFormat)
    ),
  },
  {
    header: 'Manual booking request sent',
    accessorKey: 'manualBookingRequestSent',
    Cell: ({ renderedCellValue }) => renderedCellValue && boolLabels[renderedCellValue],
  },
  {
    header: 'Confirmed manually booked',
    accessorKey: 'confirmedManuallyBooked',
    Cell: ({ renderedCellValue }) => renderedCellValue && boolLabels[renderedCellValue],
  },
];

export const renewalStatusLabels = {
  [RenewalStatusSow.IN_PROGRESS]: 'In Progress',
  [RenewalStatusSow.DECLINED]: 'Declined',
  [RenewalStatusSow.RENEWED]: 'Renewed',
};
