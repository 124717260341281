import React from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';
import Actions from 'shared/Table/Actions';
import { orderActionTitle } from 'core/labels';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { useUserPermissions } from '@EngagementsActiveNumbers/useUserPermissions';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import {
  isOrderNonAttForRoles,
  isActiveNumbersItemCanceled,
  checkIfOrderNonAttTSD,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { isOrderRequestTypesNotMatchRoles } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsActiveNumbers/utilities';

interface IWirelineEngagementsActiveNumbersRowActionsProps {
  row: IActiveNumbersItem;
}

const WirelineEngagementsActiveNumbersRowActions: React.FC<
  IWirelineEngagementsActiveNumbersRowActionsProps
> = ({ row }) => {
  const {
    activeRowId,
    activeRowMode,
    setActiveRowEdit,
    setActiveRowView,
    setActiveRowComment,
    setActiveRowCancelOrder,
    setActiveRowEscalationSupport,
  } = useTableActiveRowContext();
  const {
    isEditingAvailable, isCancelOrderAvailable, isBillingSpecialist, isProjectManager, isAdmin,
  } = useUserPermissions();

  const {
    isCancelOrderMode, isEscalationSupportMode, isCommentMode,
  } = getTableRowModeBoolean(activeRowMode);

  const rowId = get(row, 'id');
  const isCanceled = isActiveNumbersItemCanceled(row);
  const isCommentButtonSelected = isCommentMode && rowId === activeRowId;
  const isCancelOrderButtonSelected = isCancelOrderMode && rowId === activeRowId;
  const isEscalationSupportBtnSelected = isEscalationSupportMode && rowId === activeRowId;
  const isNonAttTSDForRoles = isOrderNonAttForRoles(
    row, [isBillingSpecialist, isProjectManager, isAdmin],
  );
  const isOrderNonAttTSD = checkIfOrderNonAttTSD(row);

  const isRequestTypesNotMatchRoles = isOrderRequestTypesNotMatchRoles({
    row, isBillingSpecialist,
  });

  const isEscalationSupportBtnDisabled = (
    (!!activeRowId && !isEscalationSupportBtnSelected) || isCanceled
  );

  const isCancelOrderButtonDisabled = (
    (!!activeRowId && !isCancelOrderButtonSelected)
    || isCanceled || isOrderNonAttTSD
  );

  const isCommentButtonDisabled = !!activeRowId && !isCommentButtonSelected;

  const isViewButton = !isEditingAvailable;

  const isMoreButtonDisabled = (isCancelOrderButtonDisabled
   && isCommentButtonDisabled
   && isEscalationSupportBtnDisabled)
   || isNonAttTSDForRoles
   || isRequestTypesNotMatchRoles;

  const isActiveButtonDisabled = isNonAttTSDForRoles || isRequestTypesNotMatchRoles;

  const moreButtonConfig = compact([
    {
      onClick: () => setActiveRowEscalationSupport(row),
      isDisabled: isEscalationSupportBtnDisabled,
      icon: <NextWeekOutlinedIcon />,
      text: 'Escalation Support',
    },
    isCancelOrderAvailable && {
      onClick: () => setActiveRowCancelOrder(row),
      isDisabled: isCancelOrderButtonDisabled,
      icon: <ClearIcon />,
      text: 'Cancel/Disco Request',
    },
    {
      onClick: () => setActiveRowComment(row),
      isDisabled: isCommentButtonDisabled,
      icon: <CommentIcon />,
      text: 'Order Comments',
    },
  ]);

  return (
    <Actions<IActiveNumbersItem>
      row={row}
      activeRowId={activeRowId}
      isViewButton={isViewButton}
      activeRowMode={activeRowMode}
      setActiveRowEdit={setActiveRowEdit}
      setActiveRowView={setActiveRowView}
      moreButtonConfig={moreButtonConfig}
      moreButtonTooltipTitle={orderActionTitle}
      isActionButtonVisible={isEditingAvailable}
      isMoreButtonDisabled={isMoreButtonDisabled}
      isActiveButtonDisabled={isActiveButtonDisabled}
    />
  );
};

export default WirelineEngagementsActiveNumbersRowActions;
