import React from 'react';
import CustomSingleSelect from 'shared/CustomSingleSelect';
import { getReportPeriodOptions } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTableTopToolbar/utilites';

interface IBookingReportFilter {
  reportPeriod: string | null
  setReportPeriod: (reportPeriod: string | null) => void;
}

const periodOptions = getReportPeriodOptions({ months: 12 });

const BookingReportFilter: React.FC<IBookingReportFilter> = ({
  reportPeriod, setReportPeriod,
}) => (
  <CustomSingleSelect
    isClearable
    value={reportPeriod}
    label="Booking Report"
    options={periodOptions}
    onChange={setReportPeriod}
    className="booking-report-button"
  />
);

export default BookingReportFilter;
