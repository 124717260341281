import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TabPanel from 'shared/TabPanel';
import TabsHeader from 'shared/Tabs/TabsHeader';

const Tabs = ({
  tabItemsConfig, setDefaultTabIndex, defaultTabIndex, isScrollable,
}) => {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex || 0);
  const selectedNewTab = (event, newValue) => {
    setTabIndex(newValue);
    setDefaultTabIndex(newValue);
  };

  return (
    <>
      <TabsHeader
        value={tabIndex}
        onClick={setTabIndex}
        onChange={selectedNewTab}
        isScrollable={isScrollable}
        tabItemsConfig={tabItemsConfig}
      />
      {tabItemsConfig.map((item, index) => (
        <div key={item.name.toString()}>
          <TabPanel
            value={tabIndex}
            index={index}
            className="tabs__tab-panel"
          >
            {item.children || item.name}
          </TabPanel>
        </div>
      ))}
    </>
  );
};

Tabs.propTypes = {
  tabItemsConfig: PropTypes.instanceOf(Object).isRequired,
  setDefaultTabIndex: PropTypes.func,
  defaultTabIndex: PropTypes.number,
  isScrollable: PropTypes.bool,
};

Tabs.defaultProps = {
  setDefaultTabIndex: () => {},
  defaultTabIndex: 0,
  isScrollable: false,
};

export default Tabs;
