// eslint-disable-next-line no-unused-vars
import { TBookingReportPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import getBookingSellerDataAction from '@dashboardWirelineSellerDepartment/GetBookingSellerData/actions';
import getBookingReportDataAction from '@dashboardWirelineSellerDepartment/GetBookingReportData/actions';

const getBookingTableDataAction = (
  controller,
  params: {
      page: number;
      page_size: number;
      search: string;
      ordering?: string | undefined;
    },
  bookingReportPeriod?: TBookingReportPeriod | undefined) => (
  bookingReportPeriod
    ? getBookingReportDataAction(controller, params, bookingReportPeriod)
    : getBookingSellerDataAction(controller, params)
);

export default getBookingTableDataAction;
