export enum BoolEnum {
  YES = 'YES',
  NO = 'NO',
}

export enum ROLE_STATUS {
  FUTURE = 'FUTURE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  RESOLVED_PENDING_COMPLETION = 'RESOLVED_PENDING_COMPLETION',
}

export enum COMMISSION_TYPE {
  NEW = 'NEW',
  RENEWAL = 'RENEWAL',
  RE_ORDER = 'RE_ORDER',
  DOWNGRADE = 'DOWNGRADE',
  UPGRADE = 'UPGRADE',
  CANCELED = 'CANCELED',
}
