import { bookingStatusLabels } from '@dashboardWireline/WirelineDepartments/labels';
import { BookingStatus } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';
import { COMMISSION_TYPE } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import {
  ORDER_TYPE,
  Carrier,
  System,
  Term,
  PbxInterfaceType,
} from '@EngagementsSowData/sowData.model';
import {
  orderTypeLabels,
  carrierLabels,
  systemLabels,
  termLabels,
  pbxInterfaceTypeLabels,
  commissionTypeLabels,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';

export const BookingStatusOptions = [
  {
    value: BookingStatus.PENDING_CUSTOMER_SIGNATURE,
    label: bookingStatusLabels[BookingStatus.PENDING_CUSTOMER_SIGNATURE],
  },
  {
    value: BookingStatus.PENDING_ORDER_NUMBER,
    label: bookingStatusLabels[BookingStatus.PENDING_ORDER_NUMBER],
  },
  {
    value: BookingStatus.WORKING_ENGAGEMENT,
    label: bookingStatusLabels[BookingStatus.WORKING_ENGAGEMENT],
  },
  {
    value: BookingStatus.PROJECT_BOOKED,
    label: bookingStatusLabels[BookingStatus.PROJECT_BOOKED],
  },
  {
    value: BookingStatus.BOOKED,
    label: bookingStatusLabels[BookingStatus.BOOKED],
  },
  {
    value: BookingStatus.TRANSACTIONAL,
    label: bookingStatusLabels[BookingStatus.TRANSACTIONAL],
  },
  {
    value: BookingStatus.CANCELED_ENGAGEMENT,
    label: bookingStatusLabels[BookingStatus.CANCELED_ENGAGEMENT],
  },
];

export const CarrierOptions = [
  {
    value: Carrier.ATT,
    label: carrierLabels[Carrier.ATT],
  },
  {
    value: Carrier.AVANT,
    label: carrierLabels[Carrier.AVANT],
  },
  {
    value: Carrier.INTELISYS,
    label: carrierLabels[Carrier.INTELISYS],
  },
  {
    value: Carrier.SANDLER,
    label: carrierLabels[Carrier.SANDLER],
  },
  {
    value: Carrier.TBI,
    label: carrierLabels[Carrier.TBI],
  },
  {
    value: Carrier.TCG,
    label: carrierLabels[Carrier.TCG],
  },
  {
    value: Carrier.TELARUS,
    label: carrierLabels[Carrier.TELARUS],
  },
];

export const OrderTypeOptions = [
  {
    value: ORDER_TYPE.CANCELED,
    label: orderTypeLabels[ORDER_TYPE.CANCELED],
  },
  {
    value: ORDER_TYPE.NEW_42393_UPFRONT,
    label: orderTypeLabels[ORDER_TYPE.NEW_42393_UPFRONT],
  },
  {
    value: ORDER_TYPE.NEW_50212_RESIDUAL,
    label: orderTypeLabels[ORDER_TYPE.NEW_50212_RESIDUAL],
  },
  {
    value: ORDER_TYPE.NEW_NON_AT_T,
    label: orderTypeLabels[ORDER_TYPE.NEW_NON_AT_T],
  },
  {
    value: ORDER_TYPE.RE_ORDER_42393_UPFRONT,
    label: orderTypeLabels[ORDER_TYPE.RE_ORDER_42393_UPFRONT],
  },
  {
    value: ORDER_TYPE.RE_ORDER_50212_RESIDUAL,
    label: orderTypeLabels[ORDER_TYPE.RE_ORDER_50212_RESIDUAL],
  },
  {
    value: ORDER_TYPE.RE_ORDER_NON_AT_T,
    label: orderTypeLabels[ORDER_TYPE.RE_ORDER_NON_AT_T],
  },
  {
    value: ORDER_TYPE.RENEWAL_50212_RESIDUAL,
    label: orderTypeLabels[ORDER_TYPE.RENEWAL_50212_RESIDUAL],
  },
  {
    value: ORDER_TYPE.RENEWAL_52632_RESIDUAL,
    label: orderTypeLabels[ORDER_TYPE.RENEWAL_52632_RESIDUAL],
  },
];

export const SystemOptions = [
  {
    value: System.ASAP,
    label: systemLabels[System.ASAP],
  },
  {
    value: System.BOOST,
    label: systemLabels[System.BOOST],
  },
  {
    value: System.NON_AT_T,
    label: systemLabels[System.NON_AT_T],
  },
  {
    value: System.NI_EXPRESS,
    label: systemLabels[System.NI_EXPRESS],
  },
  {
    value: System.OPUS,
    label: systemLabels[System.OPUS],
  },
  {
    value: System.RDS,
    label: systemLabels[System.RDS],
  },
  {
    value: System.SALES_EXPRESS,
    label: systemLabels[System.SALES_EXPRESS],
  },
  {
    value: System.USELL,
    label: systemLabels[System.USELL],
  },
  {
    value: System.ATT_ACC,
    label: systemLabels[System.ATT_ACC],
  },
  {
    value: System.BUSINESS_CENTER,
    label: systemLabels[System.BUSINESS_CENTER],
  },
  {
    value: System.CALNET,
    label: systemLabels[System.CALNET],
  },
  {
    value: System.CLOUDRUNNER,
    label: systemLabels[System.CLOUDRUNNER],
  },
  {
    value: System.SOSP,
    label: systemLabels[System.SOSP],
  },
  {
    value: System.SSPP,
    label: systemLabels[System.SSPP],
  },
];

export const ContractTermOptions = [
  {
    value: Term.ZERO,
    label: termLabels[Term.ZERO],
  },
  {
    value: Term.ONE,
    label: termLabels[Term.ONE],
  },
  {
    value: Term.TWO,
    label: termLabels[Term.TWO],
  },
  {
    value: Term.THREE,
    label: termLabels[Term.THREE],
  },
  {
    value: Term.FOUR,
    label: termLabels[Term.FOUR],
  },
  {
    value: Term.FIVE,
    label: termLabels[Term.FIVE],
  },
];

export const BVoIPInterfaceTypeOptions = [
  {
    value: PbxInterfaceType.SIP,
    label: pbxInterfaceTypeLabels[PbxInterfaceType.SIP],
  },
  {
    value: PbxInterfaceType.ANALOG,
    label: pbxInterfaceTypeLabels[PbxInterfaceType.ANALOG],
  },
  {
    value: PbxInterfaceType.PRI,
    label: pbxInterfaceTypeLabels[PbxInterfaceType.PRI],
  },
];

export const CommissionTypeOptions = [
  {
    value: COMMISSION_TYPE.NEW,
    label: commissionTypeLabels[COMMISSION_TYPE.NEW],
  },
  {
    value: COMMISSION_TYPE.RENEWAL,
    label: commissionTypeLabels[COMMISSION_TYPE.RENEWAL],
  },
  {
    value: COMMISSION_TYPE.RE_ORDER,
    label: commissionTypeLabels[COMMISSION_TYPE.RE_ORDER],
  },
  {
    value: COMMISSION_TYPE.DOWNGRADE,
    label: commissionTypeLabels[COMMISSION_TYPE.DOWNGRADE],
  },
  {
    value: COMMISSION_TYPE.UPGRADE,
    label: commissionTypeLabels[COMMISSION_TYPE.UPGRADE],
  },
  {
    value: COMMISSION_TYPE.CANCELED,
    label: commissionTypeLabels[COMMISSION_TYPE.CANCELED],
  },
];

export const SpidOptions = [
  {
    label: '42393',
    value: '42393',
  },
  {
    label: '50212',
    value: '50212',
  },
  {
    label: '52632',
    value: '52632',
  },
];
