import React, { useRef } from 'react';
import { Grid, Typography } from '@mui/material';
import { useAppSelector } from 'core/store';
import {
  Formik, FieldArray, FormikProps,
} from 'formik';
import CustomDialogStep from 'shared/CustomDialog/CustomDialogStep';
import { getFormOrdersProductList } from 'pages/WirelineEngagements/shared/AddOrderModalForm/utilites';
import requestTypeSchema from 'pages/WirelineEngagements/shared/AddOrderModalForm/AddOrderRequestTypeForm/AddOrderRequestTypeSchema';
import AddOrderRequestTypeItem from 'pages/WirelineEngagements/shared/AddOrderModalForm/AddOrderRequestTypeForm/AddOrderRequestTypeItem';
import { IFormikProductValues, IFormRequestTypeProductItem, IFormRequestTypeValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/product.model';

interface IAddOrderRequestTypeForm {
  onSubmit: (values: IFormRequestTypeValues) => void
  ordersData: IFormikProductValues | null
  productsRequestTypeFormData: IFormRequestTypeValues | null
  onGoToPreviousStep: (productsRequestFormData) => void
  onRemoveProducts: (products: IFormRequestTypeProductItem[]) => void
  attTSDId: number
}

const AddOrderRequestTypeForm: React.FC<IAddOrderRequestTypeForm> = ({
  onSubmit,
  attTSDId,
  ordersData,
  onRemoveProducts,
  onGoToPreviousStep,
  productsRequestTypeFormData,
}) => {
  const locations = useAppSelector((state) => state.customers.locationsByCustomerId.data);
  const formikRef = useRef<FormikProps<IFormRequestTypeValues>>(null);

  const removedProducts = useRef<IFormRequestTypeProductItem[]>([]);
  const initialvalues = getFormOrdersProductList({
    attTSDId,
    locations,
    list: ordersData?.orders || [],
    existedProductsList: productsRequestTypeFormData?.products || [],
  });

  const addProductToRemovedList = (product: IFormRequestTypeProductItem) => {
    removedProducts.current = [...removedProducts.current, product];
  };

  const onGoBackHandler = () => {
    onRemoveProducts(removedProducts.current);
    onGoToPreviousStep(formikRef.current?.values);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      innerRef={formikRef}
      initialValues={initialvalues}
      validationSchema={requestTypeSchema}
    >
      { ({ values, isSubmitting, submitForm }) => (
        <CustomDialogStep
          onClickFirstButton={onGoBackHandler}
          firstButtonText="Back"
          firstButtonVariant="outlined"
          onClickSecondButton={submitForm}
          secondButtonText="Save"
          secondButtonVariant="contained"
          isLoading={isSubmitting}
        >
          <Grid container xs={12} direction="column">
            <Grid item container xs={12} spacing={3} mb={3}>
              <Grid item xs={2}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                >
                  Location
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                >
                  Product
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                >
                  Request Type
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={3}
              alignContent="flex-start"
              className="add-order-modal-form__request-body"
            >
              <FieldArray name="products">
                { (helpers) => values.products.map((product, index) => (
                  <AddOrderRequestTypeItem
                    data={product}
                    key={product.id}
                    itemCount={index}
                    attTSDId={attTSDId}
                    canRemove={values.products.length > 1}
                    onRemove={() => {
                      helpers.remove(index);
                      addProductToRemovedList(product);
                    }}
                  />
                ))}
              </FieldArray>
            </Grid>
          </Grid>

        </CustomDialogStep>
      )}
    </Formik>
  );
};

export default AddOrderRequestTypeForm;
