import { createSlice } from '@reduxjs/toolkit';
import { IBookingDataItem } from '@dashboardWireline/WirelineDepartments/WIrelineDepartament.models';

export interface IState {
  count: number
  isLoading: boolean
  error: object | null
  results: IBookingDataItem[]
}

const initialState: IState = {
  count: 0,
  results: [],
  error: null,
  isLoading: true,
};

const BookingReportDataSlice = createSlice({
  name: 'getBookingReportData',
  initialState,
  reducers: {
    getBookingReportDataRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getBookingReportDataSuccess: (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }),
    getBookingReportDataFailure: (state, action) => ({
      ...state,
      results: [],
      isLoading: false,
      error: action.payload,
    }),
    clearBookingReportDataAction: () => initialState,
  },
});

export const {
  getBookingReportDataRequest,
  getBookingReportDataSuccess,
  getBookingReportDataFailure,
  clearBookingReportDataAction,
} = BookingReportDataSlice.actions;

export const getBookingReportDataReducer = BookingReportDataSlice.reducer;
