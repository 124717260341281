export enum Status {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED',
}

export enum Type {
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  BILLING_SPECIALIST = 'BILLING_SPECIALIST',
  CONTRACT_SPECIALIST = 'CONTRACT_SPECIALIST',
}

export interface IEscalationSupportItem {
  createdAt: string,
  createdByFullname: string,
  customerCompanyName: string,
  customerLocation: string,
  details: string,
  id: number,
  product: string,
  // eslint-disable-next-line no-restricted-globals
  status: Status,
  type: Type,
}
