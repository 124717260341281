import React from 'react';
import { Typography } from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';
import paths from 'core/routing/paths';
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import { SowDataActions } from '@EngagementsSowData/SowDataActionsButton/constsnts';
import ActionsMenuItem from '@EngagementsSowData/SowDataActionsButton/ActionsMenuItem';
import {
  quoteRequestDisabledConditions,
  orderRequestDisabledConditions,
  contractRequestDisabledMainConditions,
  contractRequestAndOrderRequestDisabledConditions,
} from '@EngagementsSowData/utilities';

interface ISowDataActionsRequestProps {
  selectedOrders: ISowDataListItem[],
  handleClose: () => void,
  setOrderAction: (orderAction: OrderActions) => void,
  setIsActionsModalOpen: (isModalOpen: boolean) => void
}

const SowDataActionsRequest: React.FC<
  ISowDataActionsRequestProps
> = ({
  selectedOrders, handleClose, setOrderAction, setIsActionsModalOpen,
}) => {
  const navigate = useNavigate();

  const isContractAndOrderRequestDisabled = selectedOrders.some(
    contractRequestAndOrderRequestDisabledConditions,
  );

  const everyOrdersHasRequestQuoteDatetime = selectedOrders.every(
    (order) => order.requestQuoteDatetime,
  );
  const isQuoteRequestDisabledByAdditionalConditions = selectedOrders.some(
    quoteRequestDisabledConditions,
  );
  const isDisableQuotesRequest = everyOrdersHasRequestQuoteDatetime
  || isQuoteRequestDisabledByAdditionalConditions;

  const isContractRequestDisabledByMainConditions = selectedOrders.some(
    contractRequestDisabledMainConditions,
  );
  const isDisableContractRequest = isContractRequestDisabledByMainConditions
   || isContractAndOrderRequestDisabled;

  const isOrderRequestDisabledByAdditionalConditions = selectedOrders.some(
    orderRequestDisabledConditions,
  );
  const isDisabledRequestOrders = isOrderRequestDisabledByAdditionalConditions
   || isContractAndOrderRequestDisabled;

  const orderIds = selectedOrders.map(({ id }) => id).join(',');

  const onRequestQuotes = async () => {
    handleClose();
    setOrderAction(OrderActions.REQUEST_QUOTES);
    setIsActionsModalOpen(true);
  };

  const onRequestContracts = () => {
    handleClose();
    setOrderAction(OrderActions.REQUEST_CONTRACTS);
    navigate({
      pathname: paths.wirelineEngagementsContractForm.split('/').pop() as string,
      search: `?${createSearchParams({ orderIds })}`,
    });
  };

  const onRequestOrders = () => {
    handleClose();
    setOrderAction(OrderActions.REQUEST_ORDERS);
    navigate({
      pathname: paths.wirelineEngagementsRequestOrder.split('/').pop() as string,
      search: `?${createSearchParams({ orderIds })}`,
    });
  };

  return (
    <>
      <Typography
        className="actions-menu__title"
        variant="h6"
        component="h2"
      >
        Request
      </Typography>
      <ActionsMenuItem
        onAction={onRequestQuotes}
        isDisable={isDisableQuotesRequest}
        content={SowDataActions.quotes}
        isRequest
      />
      <ActionsMenuItem
        onAction={onRequestContracts}
        isDisable={isDisableContractRequest}
        content={SowDataActions.contracts}
        isRequest
      />
      <ActionsMenuItem
        onAction={onRequestOrders}
        content={SowDataActions.orders}
        isDisable={isDisabledRequestOrders}
        isRequest
      />
    </>
  );
};

export default SowDataActionsRequest;
