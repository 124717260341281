import React from 'react';
import dayjs from 'dayjs';
import { dateFormat } from 'core/constants';
import RequestProvidedDate from 'shared/Table/shared/RequestProvidedDate';
import ActiveNumbersStatus from '@EngagementsActiveNumbers/ActiveNumbersStatus';
import EscalationSupportView from 'pages/WirelineEngagements/shared/EscalationSupportView';
import { BoolEnum, ROLE_STATUS, COMMISSION_TYPE } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import {
  boolLabels,
  roleStatusLabels,
  commissionTypeLabels,
  portDescriptionLabels,
  providedBodyCellStyle,
  providedHeadCellStyle,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import {
  billingSupportLabels,
  billingSupportValues,
  contractSuportValues,
  sellerRequestTypeValues,
  sellerRequestTypeLabels,
  contractOrderSupportLabels,
  projectManagerSupportValues,
  projectManagementSupportLabels,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

export const activeNumbersTitle = 'Active Numbers';

export const columns = [
  {
    accessorKey: 'pmEngagementAcceptedDate',
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
    <RequestProvidedDate requestProvidedDate={renderedCellValue as string} />
    ),
  },
  {
    accessorKey: 'billingEngagementAcceptedDate',
    minWidth: '100px',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
    <RequestProvidedDate isRequest requestProvidedDate={renderedCellValue as string} />
    ),
  },
  {
    accessorKey: 'hasEscalationRequests',
    Cell: ({ row: { original } }) => (
      <EscalationSupportView
        product={original.product as string}
        customerCompanyName={original.customerCompanyName as string}
        hasEscalationRequests={original.hasEscalationRequests as boolean}
      />
    ),
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
  },
  {
    accessorKey: 'portDescription',
    Cell: ({ renderedCellValue }) => (
      portDescriptionLabels[renderedCellValue] || renderedCellValue
    ),
  },
  {
    accessorKey: 'portOrVlanDate',
    minWidth: '100px',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue).format(dateFormat)
    ),
  },
  {
    accessorKey: 'status',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
    <ActiveNumbersStatus orderStatus={renderedCellValue} />
    ),
  },
  {
    accessorKey: 'ttuDate',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue).format(dateFormat)
    ),
  },
  {
    accessorKey: 'completionDate',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue).format(dateFormat)
    ),
  },
  {
    accessorKey: 'sellerRequestType',
    Cell: ({ renderedCellValue }) => (
      sellerRequestTypeLabels[renderedCellValue as sellerRequestTypeValues] || renderedCellValue
    ),
  },
  {
    accessorKey: 'contractOrderSupport',
    Cell: ({ renderedCellValue }) => (
      contractOrderSupportLabels[renderedCellValue as contractSuportValues] || renderedCellValue
    ),
  },
  {
    accessorKey: 'projectManagementSupport',
    Cell: ({ renderedCellValue }) => (
      projectManagementSupportLabels[renderedCellValue as projectManagerSupportValues]
      || renderedCellValue
    ),
  },
  {
    accessorKey: 'billingSupport',
    Cell: ({ renderedCellValue }) => (
      billingSupportLabels[renderedCellValue as billingSupportValues] || renderedCellValue
    ),
  },
  {
    accessorKey: 'commissionType',
    Cell: ({ renderedCellValue }) => (
      commissionTypeLabels[renderedCellValue as COMMISSION_TYPE] || renderedCellValue
    ),
  },
  {
    accessorKey: 'renewableService',
    Cell: ({ renderedCellValue }) => (
      boolLabels[renderedCellValue as BoolEnum] || renderedCellValue
    ),
  },
  {
    accessorKey: 'contractSpecialistStatus',
    Cell: ({ renderedCellValue }) => (
      roleStatusLabels[renderedCellValue as ROLE_STATUS] || renderedCellValue
    ),
  },
  {
    accessorKey: 'projectManagementStatus',
    Cell: ({ renderedCellValue }) => (
      roleStatusLabels[renderedCellValue as ROLE_STATUS] || renderedCellValue
    ),
  },
  {
    accessorKey: 'billingSpecialistStatus',
    Cell: ({ renderedCellValue }) => (
      roleStatusLabels[renderedCellValue as ROLE_STATUS] || renderedCellValue
    ),
  },
];

export const endpointToGetActiveNumbersListSchemaName = '/api/sales/active-numbers/';

export const dynamicActiveNumbersProductSchemaName = 'DynamicActiveNumbersProductsSerializerSchema';

export const editActiveNumbersTitle = 'Edit Active Numbers';
export const viewActiveNumbersTitle = 'View Active Numbers';

export const endpointToGetActiveNumbersSchemaName = '/api/sales/active-numbers/{id}/';

export const getActiveNumbersItemNotifierMessage = {
  error: 'Failed to load Active Numbers item.',
};

export const patchActiveNumbersItemNotifierMessage = {
  success: 'Active Numbers item updated successfully.',
  error: 'Failed to update Active Numbers item.',
};

export const engagementAcceptedPMDateKey = 'pmEngagementAcceptedDate';
export const engagementAcceptedBSDateKey = 'billingEngagementAcceptedDate';
