import React, { useEffect } from 'react';
import { useAppDispatch } from 'core/store';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import { columns } from '@dashboardWireline/WirelineDepartments/constants';
import BookingTable from '@dashboardWirelineContractSpecialistDepartment/BookingInfo/BookingTable';
import { useBookingTableColumns } from '@dashboardWireline/WirelineDepartments/useBookingTableColumns';
import { clearBookingCSDataAction } from '@dashboardWirelineContractSpecialistDepartment/GetBookingCSData';
import getBookingCSDataAction from '@dashboardWirelineContractSpecialistDepartment/GetBookingCSData/actions';
import './BookingInfo.scss';

const BookingInfo = () => {
  const dispatch = useAppDispatch();

  const { bookingColumns, isLoading: isLoadingTSDsList } = useBookingTableColumns(columns);

  useEffect(() => () => {
    dispatch(clearBookingCSDataAction());
  }, []);

  return (
    <TableProvider
      columns={bookingColumns}
      getDataAction={getBookingCSDataAction}
      id={tableIds.bookingContractSpecialistInfo}
    >
      <BookingTable
        isLoadingTableFiltersData={isLoadingTSDsList}
      />
    </TableProvider>
  );
};

export default BookingInfo;
