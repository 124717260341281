import React from 'react';
import {
  MRT_TableInstance as TableInstance,
  MRT_ToggleFiltersButton as ToggleFiltersButton,
  MRT_ShowHideColumnsButton as ShowHideColumnsButton,
  MRT_GlobalFilterTextField as GlobalFilterTextField,
  MRT_FullScreenToggleButton as FullScreenToggleButton,
} from 'material-react-table';
import { TRow } from 'shared/Table/Table.models';

interface ITableToolbarProps {
  table:TableInstance <TRow>,
  toolBarAction?: JSX.Element,
  showFilterButton?: boolean,
  actionsButton?: JSX.Element,
  toolBarCustomSearch?: JSX.Element,
  toolBarStartContent?: JSX.Element,
}

const TableToolbar = ({
  table, toolBarAction, showFilterButton, actionsButton, toolBarCustomSearch, toolBarStartContent,
}: ITableToolbarProps) => (
  <div className="table__toolbar">
    {toolBarStartContent}
    {toolBarCustomSearch}
    {!toolBarCustomSearch && <GlobalFilterTextField table={table} />}
    <div className="table__toolbar-btn">
      <ShowHideColumnsButton table={table} title="Manage Columns" />
      {showFilterButton && <ToggleFiltersButton table={table} />}
      <FullScreenToggleButton table={table} />
      {actionsButton}
    </div>
    <div className="table__toolbar-actions">
      {toolBarAction}
    </div>
  </div>
);

TableToolbar.defaultProps = {
  showFilterButton: false,
  actionsButton: null,
  toolBarAction: null,
  toolBarCustomSearch: null,
  toolBarStartContent: null,
};
export default TableToolbar;
