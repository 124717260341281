// eslint-disable-next-line no-unused-vars
import React, { SyntheticEvent } from 'react';
import {
  Box,
  Button,
  TextField,
} from '@mui/material';

interface ICommentsTextAreaProps {
  newCommentText: string
  error: string
  editCommentId: string | number
  onChangeComment: (e: SyntheticEvent) => void
  onCreateNewComment: (e: SyntheticEvent) => void
  onUpdateComment: (e: SyntheticEvent) => void
  onCancel: (e: SyntheticEvent) => void
  canLeaveComments: boolean
}

const CommentsTextArea: React.FC<ICommentsTextAreaProps> = ({
  newCommentText,
  error,
  editCommentId,
  onChangeComment,
  onCreateNewComment,
  onUpdateComment,
  onCancel,
  canLeaveComments,
}) => {
  const placeholder = editCommentId ? 'Please confirm changes' : 'Enter comments here';

  const onClickButton = editCommentId ? onUpdateComment : onCreateNewComment;
  const buttonText = editCommentId ? 'Confirm' : 'Add comment';
  const isButtonDisabled = editCommentId ? false : !newCommentText.length;

  return (
    <Box className="comments-sidebar__bottom-block">
      {canLeaveComments && (
        <TextField
          placeholder={placeholder}
          variant="outlined"
          disabled={Boolean(editCommentId)}
          multiline
          rows={2}
          fullWidth
          error={!!error}
          helperText={error && error}
          inputProps={{ className: 'comments-sidebar__textarea' }}
          value={newCommentText}
          onChange={onChangeComment}
        />
      )}
      <div className="comments-sidebar__bottom-block__buttons">
        {canLeaveComments && (
          <Button
            onClick={onClickButton}
            disabled={isButtonDisabled}
            variant="contained"
          >
            {buttonText}
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          cancel
        </Button>
      </div>
    </Box>
  );
};

export default CommentsTextArea;
