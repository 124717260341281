/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { ISalesActiveNumbersMetaItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { getSalesActiveNumbersMeta } from 'core/api/sales.api';

export interface IState {
    isLoading: boolean;
    error: object | null;
    data: ISalesActiveNumbersMetaItem[];
}

const initialState: IState = {
  data: [],
  error: null,
  isLoading: true,
};

export const getSalesActiveNumbersMetaAction = createAsyncThunk(
  'getSalesActiveNumbersMetaAction',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getSalesActiveNumbersMeta();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const SalesActiveNumbersMetaSlice = createSlice({
  name: 'getSalesActiveNumbersMeta',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getSalesActiveNumbersMetaAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSalesActiveNumbersMetaAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getSalesActiveNumbersMetaAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const { clear: clearSalesActiveNumbersMetaAction } = SalesActiveNumbersMetaSlice.actions;

export const salesActiveNumbersMetaReducer = SalesActiveNumbersMetaSlice.reducer;
